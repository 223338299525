export interface ModelMailContact {
    name: string;
    email: string;
    phoneNumber: string;
    subject: string;
    message: string;
    type: EContactType;
    captcha: string;
}

export enum EContactType {
    Idea = 'Proposition d\'idée',
    Apply = 'Candidature'
}