import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModelPartner } from 'src/app/model/model-partner';

@Component({
  selector: 'app-marketplace-item',
  templateUrl: './marketplace-item.component.html',
  styleUrl: './marketplace-item.component.scss'
})
export class MarketplaceItemComponent {

  @Input() modelPartner!: ModelPartner;

  @Input() width: string = "15rem";
  @Input() height: string = "10rem";


  @Output() onClickEvent: EventEmitter<string> = new EventEmitter<string>();

  
  onClick() {
    this.onClickEvent.emit(this.modelPartner.key);
  }
  

}
