import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent {

  // Icon
  @Input() iconSrc!: string;
  @Input() iconAlt!: string;

  // Couleur de l'index
  @Input() stepIndexColor!: string;
  @Input() stepIconSrc?: string;
  @Input() stepIconAlt?: string;

  // Titre
  @Input() title!: string;

  // Description
  @Input() description!: string;

}
