import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-tool',
  templateUrl: './tool.component.html',
  styleUrls: ['./tool.component.scss']
})
export class ToolComponent {


  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 1150px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

    @Input() toolImgSrc!: string;
    @Input() toolImgAlt!: string;

    @Input() toolTitle! : string;

    @Input() toolIcons! : string[];
    @Input() toolAlts!: string[];

    constructor (private breakpointObserver: BreakpointObserver) {

    }
}
