<div [ngClass]="!(isHandset$ | async) ? 'tools-panel' : 'tools-panel-mobile'">
    <!-- Img Middle-->
    <div *ngIf="!(isHandset$ | async)" class="tools-inner-circle">
        <img src="assets/images/logo/inner_circle_logo.svg" alt="inner_circle_logo"/>
    </div>
    <!-- Img Circle-->
    <img *ngIf="!(isHandset$ | async)" class="tools-elipsis" src="assets/images/logo/ellipse.svg" alt="ellipse"/>
    <!-- ligne -->
    <img *ngIf="(isHandset$ | async)" class="tools-line" src="assets/images/landings/page/steps/line.svg" alt="Line"/>

    <!-- Tools -->
    <div [ngClass]="!(isHandset$ | async) ? 'tools' : 'tools-mobile'">
        <ng-content></ng-content>
    </div>
</div>

