<!-------------------------------- Panel Etapes version Vertical ------------------------------------------>
<div *ngIf="isOrientationVertical() && !(isHandset$ | async)" class="app-step">
  <!-- Panel Etapes -->
  <div class="steps">
    <!-- Etapes -->
    <div
      [style.justify-content]="!isEven ? 'flex-end' : 'flex-start'"
      class="panel-step"
      *ngFor="
        let step of steps;
        let index = index;
        let isEven = even;
        let last = last
      "
    >
      <!-- Vertical Line-->
      <div *ngIf="!last" class="step-line-container" #container>
        <div
          *ngFor="let item of items; let last = last"
          class="step-line"
          [style.top]="item.top"
        ></div>
      </div>
      <div
        class="number-and-step"
      >
        <!-- Number pour les étapes paires -->
        <div *ngIf="!isEven">
          <div [style.color]="step.stepIndexColor" class="step-number">
            <div *ngIf="!step.stepIconSrc">{{ index + 1 }}</div>
            <img
              *ngIf="step.stepIconSrc"
              src="{{ step.stepIconSrc }}"
              alt="{{ step.stepIconAlt }}"
            />
          </div>
        </div>
        <!-- Step -->
        <div class="step">
          <div class="step-header">
            <img
              *ngIf="step.iconSrc"
              src="{{ step.iconSrc }}"
              alt="{{ step.iconAlt }}"
            />
            <div class="step-text fs-20 fw-600 primary-color">{{ step.title }}</div>
          </div>
          <div class="step-text fs-16 fw-400 primary-color">{{ step.description }}</div>
        </div>
        <!-- Number pour les étapes impaires -->
        <div *ngIf="isEven">
          <div [style.color]="step.stepIndexColor" class="step-number">
            <div *ngIf="!step.stepIconSrc">{{ index + 1 }}</div>
            <img
              *ngIf="step.stepIconSrc"
              src="{{ step.stepIconSrc }}"
              alt="{{ step.stepIconAlt }}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Footer -->
  <div class="stepAnimation footers">
    <ng-content select="[appStepFooter]"></ng-content>
  </div>
</div>

<!-------------------------------- Panel Etapes version Horizontal ------------------------------------------>
<div
  *ngIf="isOrientationHorizontal() && !(isHandset$ | async)"
  class="app-step-mobile"
>
  <!-- Panel Etapes-->
  <div class="steps-horizontal">
    <!-- Etapes -->
    <div
      class="panel-step-horizontal"
      *ngFor="let step of steps; let index = index; let last = last"
    >
      <!-- Horizontal Line-->
      <div *ngIf="!last" class="step-line-container-horizontal" #container>
        <div
          *ngFor="let item of items; let last = last"
          class="step-line-horizontal"
          [style.left]="item.left"
        ></div>
      </div>

      <div class="stepAnimationHorizontal number-and-step-horizontal">
        <!-- Number pour les étapes -->
        <div [style.color]="step.stepIndexColor" class="step-number-horizontal">
          <div *ngIf="!step.stepIconSrc">{{ index + 1 }}</div>
          <img
            *ngIf="step.stepIconSrc"
            src="{{step.stepIconSrc}}"
            alt="{{step.stepIconAlt}}"
          />
        </div>

        <!-- Step -->
        <div class="step-horizontal">
          <div class="step-header">
            <img *ngIf="step.iconSrc" src="{{ step.iconSrc }}" alt="{{ step.iconAlt }}"/>
            <div class="step-text fs-20 fw-600 primary-color">{{step.title}}</div>
          </div>
          <div class="step-text fs-16 fw-400 primary-color">{{step.description}}</div>
        </div>
      </div>
    </div>
  </div>

  <!--Footer -->
  <div class="stepAnimationHorizontal footers">
    <ng-content select="[appStepFooter]"></ng-content>
  </div>
</div>

<!-------------------------------- Panel Etapes version Mobile ------------------------------------------>
<div *ngIf="isHandset$ | async" class="app-step-mobile">
  <div class="steps-mobile">
    <!-- Etapes -->
    <div
      class="panel-step-mobile"
      *ngFor="let step of steps; let index = index; let last = last"
    >
      <!-- Horizontal Line-->
      <div
        *ngIf="!last"
        [ngClass]="
          isOrientationHorizontal()
            ? 'step-line-container-mobile-margin'
            : 'step-line-container-mobile'
        "
        #container
      >
        <div
          *ngFor="let item of items; let last = last"
          class="step-line-horizontal"
          [style.left]="item.left"
        ></div>
      </div>

      <div
        class="stepAnimationHorizontal"
        [ngClass]="
          isOrientationHorizontal()
            ? 'number-and-step-horizontal'
            : 'number-and-step-mobile'
        "
      >
        <!-- Number pour les étapes -->
        <div [style.color]="step.stepIndexColor" class="step-number">
          <div *ngIf="!step.stepIconSrc">{{ index + 1 }}</div>
          <img
            *ngIf="step.stepIconSrc"
            src="{{ step.stepIconSrc }}"
            alt="{{ step.stepIconAlt }}"
          />
        </div>
        <!-- Step -->
        <div class="step-mobile">
          <div class="step-header">
            <img
              *ngIf="step.iconSrc"
              src="{{ step.iconSrc }}"
              alt="{{ step.iconAlt }}"
            />
            <div class="step-text fs-20 fw-600 primary-color">
              {{ step.title }}
            </div>
          </div>
          <div class="step-text fs-16 fw-400 primary-color">
            {{ step.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Footer -->
  <div class="stepAnimationHorizontal footers">
    <ng-content select="[appStepFooter]"></ng-content>
  </div>
</div>
