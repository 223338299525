import { Component } from '@angular/core';
import { AbstractPageComponent } from '../abstract/page/abstract.page.component';
import { ScrollAnimation } from 'src/app/utils/scroll-animations';
import { Subscription, interval } from 'rxjs';
import { trigger, style, transition, animate } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-smart',
  templateUrl: './smart.component.html',
  styleUrl: './smart.component.scss',
  animations: [
    trigger('changeState', [
      transition(':enter', [
        // :enter est un alias pour 'void => *'
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        // :leave est un alias pour '* => void'
        animate('1s', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class SmartComponent extends AbstractPageComponent {
  // Liste des clients Moffi
  clientsSmart: string[] = [
    'tingari',
    'carrefour',
    'bouygues',
    'bedrock',
    'at_home',
    'nhood',
  ];

  listState: string[] = ['occupied', 'available'];
  currentState: string = 'occupied';
  currentIndex: number = 0;
  subscriptionStateChange?: Subscription;

  override ngOnInit(): void {
    super.ngOnInit();

    if (isPlatformBrowser(this.platformId)) {
      this.subscriptionStateChange = interval(4000).subscribe(() => {
        this.changeCurrentState();
      });
    }
  }

  changeCurrentState() {
    this.currentIndex = (this.currentIndex + 1) % this.listState.length;
    this.currentState = this.listState[this.currentIndex];
  }

  setHeaderMetadatas() {
    this.title.setTitle(this.getTranslation('smart.metadata.title'));
    this.meta.updateTag({
      name: 'description',
      content: this.getTranslation('smart.metadata.description'),
    });
  }

  launchAnimations() {
    // Animations du header
    let fadePanelHeader: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelHeader.handleScroll(
      '.fadePanelHeader',
      'fadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );
    // Animation des icônes du header
    let fadePanelRight: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelRight.handleScroll(
      '.fadePanelRight',
      'dropAndFadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );

    // Petit timer avant de lancer la partie gauche
    setTimeout(() => this.addAnimationIconLeft(), 200);

    // Animation du panel Client
    let fadePanelClient: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelClient.handleScroll(
      '.fadePanelClient',
      'fadeIn 0.5s ease-in-out forwards',
      0.15,
      0
    );
    // Animation du panel Definition
    let fadePanelDefinition: ScrollAnimation = new ScrollAnimation(
      this.document
    );
    fadePanelDefinition.handleScroll(
      '.fadePanelDefinition',
      'fadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );
    // Animation du panel Bénéfices
    let fadePanelBenefices: ScrollAnimation = new ScrollAnimation(
      this.document
    );
    fadePanelBenefices.handleScroll(
      '.fadePanelBenefices',
      'fadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );
    // Animation du panel Booking
    this.createAnimationHorizontalPanel('.fadePanelBooking');
    // Animation du panel Signs
    let fadePanelSigns: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelSigns.handleScroll(
      '.fadePanelSigns',
      'fadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );
    // Animation du panel Security
    let fadePanelSecurity: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelSecurity.handleScroll(
      '.fadePanelSecurity',
      'fadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );

    // Animation du déplacement du mobile
    let mobileAnim: ScrollAnimation = new ScrollAnimation(this.document);
    mobileAnim.handleScroll(
      '.securityImgMobile',
      'mooveMobile 1s ease-in-out forwards',
      1.5,
      0
    );
    // Animation du déplacement du mobile
    let lockAnim: ScrollAnimation = new ScrollAnimation(this.document);
    lockAnim.handleScroll(
      '.lockAnim',
      'changeImgLock 0.7s ease-in-out forwards',
      2,
      0
    );
    // Animation de l'apparition du click
    let popImgClick: ScrollAnimation = new ScrollAnimation(this.document);
    popImgClick.handleScroll(
      '.imgClick',
      'scaleIn 1s ease-in-out forwards',
      2.5,
      0
    );

    // Animation du panel Partners
    let fadePanelPartners: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelPartners.handleScroll(
      '.fadePanelPartners',
      'fadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );
    // Animation du panel Support
    let fadePanelSupport: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelSupport.handleScroll(
      '.fadePanelSupport',
      'fadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );
    // Animation du panel Testimony
    let fadePanelTestimony: ScrollAnimation = new ScrollAnimation(
      this.document
    );
    fadePanelTestimony.handleScroll(
      '.fadePanelTestimony',
      'fadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );
  }

  addAnimationIconLeft() {
    let fadePanelLeft: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelLeft.handleScroll(
      '.fadePanelLeft',
      'dropAndFadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );
  }
}
