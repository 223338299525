<div [style.width]="width" [style.height]="height" class="item-container" (click)="onClick()">
  <div class="item-content">
    <img src="assets/images/landings/marketplace/{{ modelPartner.key }}/logo.svg" alt="logo" />
    <div class="item-tags">
      <div *ngFor="let tag of modelPartner.tagsEnum; let last = last" class="item-tags">
        <span class="tag">{{ tag | translate }}</span>
        <span *ngIf="!last" class="item-separator"></span>
      </div>
    </div>
    <div class="desc">{{ modelPartner.description | translate }}</div>
  </div>
</div>
