import { Component, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ScrollEventService } from 'src/app/service/scroll-event/scroll-event.service';
import { MatSidenavContent } from '@angular/material/sidenav';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent {
  @ViewChild('sidenavContent') sidenavContent!: MatSidenavContent;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 1150px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private scrollEventService : ScrollEventService) {}

  sendScrollEvent(elementRef: MatSidenavContent) {
    this.scrollEventService.emitScrollEvent(elementRef.getElementRef().nativeElement.scrollTop);
  }
}
