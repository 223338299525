import { Component } from '@angular/core';
import { AbstractPageComponent } from '../abstract/page/abstract.page.component';

@Component({
  selector: 'app-parking',
  templateUrl: './parking.component.html',
  styleUrl: './parking.component.scss'
})
export class ParkingComponent extends AbstractPageComponent {

  clientsParking: string[] = [
    'nexia',
    'animalis',
    'carrefour',
    'kara_pass',
    'keyrus',
    'sante_vet'
  ]

  assetsTableData: any[] = [
    {
      assetKey: 'parking.assets.stats',
      withoutMoffi: false,
      withMoffi: true,
    },
    { assetKey: 'parking.assets.control', 
      withoutMoffi: false, 
      withMoffi: true },
    {
      assetKey: 'parking.assets.available',
      withoutMoffi: false,
      withMoffi: true,
    },
    {
      assetKey: 'parking.assets.fair_distribution',
      withoutMoffi: false,
      withMoffi: true,
    },
    { assetKey: 'parking.assets.optimisation', 
      withoutMoffi: false, 
      withMoffi: true },
    {
      assetKey: 'parking.assets.charging_station',
      withoutMoffi: false,
      withMoffi: true,
    },
    {
      assetKey: 'parking.assets.tool',
      withoutMoffi: false,
      withMoffi: true,
    }
  ];

  assetsTableDataMobile: any[] = [
    { assetKey: 'parking.assets.stats' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'parking.assets.control' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'parking.assets.available' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'parking.assets.fair_distribution' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'parking.assets.optimisation' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'parking.assets.charging_station' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'parking.assets.tool' },
    { assetKey: '', withoutMoffi: false, withMoffi: true, isLastElement: true },
  ];

  override setHeaderMetadatas(): void {
    this.title.setTitle(this.getTranslation('parking.metadata.title'));
    this.meta.updateTag({
      name: 'description',
      content: this.getTranslation('parking.metadata.description'),
    });
  }
  override launchAnimations(): void {
    throw new Error('Method not implemented.');
  }

}
