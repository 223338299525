import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModelDefinition } from 'src/app/model/model-definition';

@Component({
  selector: 'app-glossary-item',
  templateUrl: './glossary-item.component.html',
  styleUrl: './glossary-item.component.scss'
})
export class GlossaryItemComponent {

  @Input() letter! : string;
  @Input() listDefinition!: ModelDefinition[];

  @Input() width: string = "15rem";
  @Input() height: string = "100%";


  @Output() onClickEvent: EventEmitter<string> = new EventEmitter<string>();

  
  onClick(key: string) {
    this.onClickEvent.emit(key);
  }

}
