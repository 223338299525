import { Component } from '@angular/core';
import { AbstractPageComponent } from '../abstract/page/abstract.page.component';

@Component({
  selector: 'app-message-envoye',
  templateUrl: './message-envoye.component.html',
  styleUrl: './message-envoye.component.scss'
})
export class MessageEnvoyeComponent extends AbstractPageComponent {


  setHeaderMetadatas() {
    this.title.setTitle('');
    this.meta.updateTag({name: 'description', content: ''})
  }


  launchAnimations() { 
    /* no animations on this page */ 
  }
}
