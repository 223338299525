<div class="page-content">
    <!-- Formulaire -->
    <div class="page-horizontal-panel" style="margin-top: 4rem;">
        <!-- Formulaire de Contact -->
        <div [ngClass]="!(isHandset$ | async) ? 'form-contact-container' : 'form-contact-container-mobile'" class="fade-panel fadePanelHeader">
            <!-- Titre -->
            <h3 class="fs-21 fw-600">{{'idea.form.title' | translate}}</h3>
            <form class="form-contact" [formGroup]="form" (ngSubmit)="sendMail(formDirective)" #formDirective="ngForm">
                <!-- Prénom & Nom-->
                <moffi-ui-textbox style="width: 100%;" 
                    id="name" 
                    name="name"
                    [formControl]="getFormControl('name')"
                    type="text" 
                    width="100%"
                    [errorIcon]="true"
                    [customErrorMessages]="errorKeys"
                    label="{{'contact.form.name.label' | translate}}" 
                    placeholder="{{'contact.form.name.placeholder' | translate}}"
                    [required]="true"
                    [enabled]="true"
                    ngDefaultControl>
                </moffi-ui-textbox>
                
                <div style="width: 100%;" [ngClass]="!(isHandset$ | async) ? 'form-group' : ''">
                    <!-- Email -->
                    <moffi-ui-textbox style="width: 50%;" 
                        id="mail" 
                        name="mail"
                        type="email" 
                        [errorIcon]="true"
                        [formControl]="getFormControl('mail')"
                        [customErrorMessages]="errorKeys"
                        label="{{'contact.form.mail.label' | translate}}" 
                        placeholder="{{'contact.form.mail.placeholder' | translate}}"
                        [required]="true"
                        width="100%"
                        ngDefaultControl>
                    </moffi-ui-textbox>
                    <!-- Téléphone -->
                    <moffi-ui-textbox style="width: 50%;" 
                        id="phone" 
                        name="phone"
                        type="text" 
                        [errorIcon]="true"
                        [formControl]="getFormControl('phone')"
                        [customErrorMessages]="errorKeys"
                        label="{{'contact.form.phone.label' | translate}}" 
                        placeholder="{{'contact.form.phone.placeholder' | translate}}"
                        [required]="true"
                        initialValue=""
                        width="100%"
                        ngDefaultControl>
                    </moffi-ui-textbox>
                </div>
               
                <!-- Message -->
                <moffi-ui-textarea style="width: 100%;" 
                    id="message" 
                    name="message"
                    label="{{'idea.form.message.label' | translate}}" 
                    placeholder="{{'idea.form.message.placeholder' | translate}}" 
                    [required]="true"
                    [minLength]="2"
                    resize="none"
                    [errorIcon]="true"
                    [formControl]="getFormControl('message')"
                    [customErrorMessages]="errorKeys"
                    [autosizeEnabled]="true"
                    width="100%"
                    height="10rem"
                    ngDefaultControl>
                </moffi-ui-textarea>

                <!-- Captcha -->
                <div class="captcha-container">
                    <re-captcha id="captcha" name="captcha" formControlName="captcha" [formControl]="getFormControl('captcha')" required></re-captcha>
                    <div class="captcha-error" *ngIf="!form.value.captcha && captchaError">
                        <mat-icon style="margin-left: 1rem;">error_outline</mat-icon>
                        {{'contact.form.name.error.mandatory' | translate}}
                    </div>
                </div>

                <div class="fadePanel">
                    <moffi-ui-button buttonType="light"  type="submit" label="{{'contact.form.button' | translate}}" srcIcon="/assets/images/icon/icon_arrow_button.svg"></moffi-ui-button>
                </div>
            </form>
        </div>

        <!-- Texte -->
        <div [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-item' : 'page-horizontal-panel-item-mobile'">
            <img class="fade-panel fadePanelHeader" src="assets/images/landings/page/hands/hand_contact.svg" alt="icon_hand_contact"/>
            <h2 class="fade-panel fadePanelHeader" appColoredText [textColor]="'#074DCE'" textKey="idea.catchphrase.title"></h2>
            <h3 class="fade-panel fadePanelHeader page-horizontal-panel-item-text">{{'idea.catchphrase.description1' | translate}}</h3>
        </div>
    </div>

    <!----------------------- Clients ------------------------->
    <app-list-client [style]="!(isHandset$ | async) ? 'margin-bottom: 8rem;' : 'margin-bottom: 1rem;'" 
        class="page-panel panel-footer fade-panel fadePanelClient" title="{{'flex.clients.description' | translate}}" [clients]="clients"></app-list-client>



</div>

