import { Component, ContentChildren, QueryList } from '@angular/core';
import { ToolComponent } from './tool/tool.component';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-tools-panel',
  templateUrl: './tools-panel.component.html',
  styleUrls: ['./tools-panel.component.scss']
})
export class ToolsPanelComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 1150px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  // Liste des item tool
  @ContentChildren(ToolComponent) tools!: QueryList<ToolComponent>;

  constructor (private breakpointObserver: BreakpointObserver) {

  }

}
