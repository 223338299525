import { Component, Input,ContentChildren, QueryList } from '@angular/core';
import { PanelListItemComponent } from './panel-list-item/panel-list-item.component';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-panel-list',
  templateUrl: './panel-list.component.html',
  styleUrls: ['./panel-list.component.scss']
})
export class PanelListComponent {

  // Titre
  @Input() title!: string;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 1150px)')
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  // Liste des sous-menu enfants
  @ContentChildren(PanelListItemComponent) listItem!: QueryList<PanelListItemComponent>;

  constructor (private breakpointObserver: BreakpointObserver){
    
  }
}
