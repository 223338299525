import { Component } from '@angular/core';
import { AbstractPageComponent } from '../abstract/page/abstract.page.component';
import { ScrollAnimation } from 'src/app/utils/scroll-animations';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import {
  EContactType,
  ModelMailContact,
} from 'src/app/model/model-mail-contact';

@Component({
  selector: 'app-contact-idea',
  templateUrl: './contact-idea.component.html',
  styleUrl: './contact-idea.component.scss',
})
export class ContactIdeaComponent extends AbstractPageComponent {

  // Controllers
  phoneFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\+?(?:\d ?){6,14}\d$/),
  ]);
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  errorKeys = {};
  captchaError: boolean = false;

  //Form Data
  form: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    mail: this.emailFormControl,
    phone: this.phoneFormControl,
    message: new FormControl('', Validators.required),
    captcha: new FormControl(null, Validators.required),
  });

  override ngOnInit() {
    super.ngOnInit();

    // OnLangChange
    this.translateService.onLangChange.subscribe(() => {
      // Erreurs
      this.errorKeys = {
        email: this.getTranslation('contact.form.name.error.format'),
        pattern: this.getTranslation('contact.form.name.error.format'),
        required: this.getTranslation('contact.form.name.error.mandatory'),
      };
    });

    Promise.all([
      this.getTranslation('contact.form.name.error.format'),
      this.getTranslation('contact.form.name.error.format'),
      this.getTranslation('contact.form.name.error.mandatory'),
    ])
      .then((translations) => {
        this.errorKeys = {
          email: translations[0],
          pattern: translations[1],
          required: translations[2],
        };
      })
      .catch((error) => {
        console.error('Error fetching translations:', error);
      });
  }

  getFormControl(key: string): FormControl {
    return this.form.get(key) as FormControl;
  }

  setHeaderMetadatas() {
    this.title.setTitle(this.getTranslation('idea.metadata.title'));
    this.meta.updateTag({
      name: 'description',
      content: this.getTranslation('idea.metadata.description'),
    });
  }

  launchAnimations() {
    // Animations du header
    let fadePanelHeader: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelHeader.handleScroll(
      '.fadePanelHeader',
      'fadeIn 0.8s ease-in-out forwards',
      0.2,
      0
    );

    // Animation du panel Client
    let fadePanelClient: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelClient.handleScroll(
      '.fadePanelClient',
      'fadeIn 0.5s ease-in-out forwards',
      0.15,
      0
    );
  }

  /**
   * Envoi le mail de contact
   * @param formDirective
   */
  sendMail(formDirective: FormGroupDirective) {
    if (this.form.valid) {
      this.captchaError = false;
      // Si tous les champs sont valides
      let modelMailContact: ModelMailContact = {
        name: this.form.value.name,
        email: this.form.value.mail,
        phoneNumber: this.form.value.phone,
        subject: '',
        message: this.form.value.message,
        type: EContactType.Idea,
        captcha: this.form.value.captcha,
      };

      this.apiService.sendMailContact(modelMailContact).subscribe({
        next: (result) => {
          this.resetFields(formDirective);
          this.redirectTo('message_sent');
        },
        error: (e) => {
          this.openSnackBar(
            "Une erreur s'est produite lors de l'envoie du message. Veuillez réessayer plus tard."
          );
        },
        complete: () => console.info('complete'),
      });
    } else {
      this.form.markAllAsTouched();
      let captchaErrors = this.form.get('captcha')?.errors;
      if (captchaErrors) {
        this.captchaError = true;
      }
    }
  }

  /**
   * Réinitialise tous les champs
   * @param formDirective
   */
  resetFields(formDirective: FormGroupDirective) {
    // Réinitialiser les valeurs
    this.form.reset();
    formDirective.resetForm();
  }
}
