<div class="page-content">
    <!----------------------- Bouton Retour ------------------------->
    <div [ngClass]="!(isHandset$ | async) ? 'return-container' : 'return-container-mobile'" class="page-panel fadePanelHeader fade-panel" (click)="redirectTo('marketplace')">
        <mat-icon fontIcon="arrow_back"></mat-icon>
        {{'global.back' | translate}}
    </div>
    


    <!----------------------- Header ------------------------->
    <div [ngClass]="!(isHandset$ | async) ? 'page-header' : 'page-header-mobile'" class="page-panel">
        <!-- Titre -->
        <div class="page-panel" style="margin-top: 3rem;">
            <div class="page-panel-title">
                <!-- Logo -->
                <img class="fadePanelHeader fade-panel" src="assets/images/landings/marketplace/{{modelPartner?.key}}/template_logo.svg" alt="logo"/>
                <!-- Titre -->
                <h1 class="fadePanelHeader fade-panel" >
                    {{modelPartner?.name}}
                    <span>{{'marketplace.title.and' | translate}} </span>
                    <span appColoredText [textColor]="'#074DCE'" textKey="marketplace.title.moffi"></span>
                </h1>
                <h4 class="fadePanelHeader fade-panel">{{(modelPartner?.description ?? '') | translate}}</h4>
            </div>
            <!-- Tags-->
            <div class="partner-tag-container fadePanelHeader fade-panel">
                <div class="partner-tag" *ngFor="let tag of modelPartner?.tagsEnum">{{tag | translate}}</div>
            </div>
        </div>
    </div>


    <!----------------------- Description ------------------------->
    <div *ngIf="!(isHandset$ | async)" class="page-panel" style="margin-top: 8rem;" >
        <div class="page-horizontal-panel" style="width: calc(min(110rem, 100vw));">
            <!-- Texte -->
            <div class="partner-text fadePanelHeader fade-panel">
                <!-- Title-->
                <h2  style="margin-bottom: 2rem;">{{(modelPartner?.template?.title ?? '') | translate}}</h2>
                <!-- Text 1 -->
                <h3 class="fs-16" *ngIf="modelPartner?.template?.introduction?.text1">{{(modelPartner?.template?.introduction?.text1?? '') | translate}}</h3>
                <!-- List -->
                <ul *ngIf="modelPartner?.template?.introduction?.list" class="partner-list">
                    <li *ngFor="let item of modelPartner?.template?.introduction?.list">
                        <h3 class="fs-16">{{item | translate}}</h3>
                    </li>
                </ul>
                <!-- Text 2 -->
                <h3 class="fs-16" *ngIf="modelPartner?.template?.introduction?.text2">{{(modelPartner?.template?.introduction?.text2 ?? '') | translate}}</h3>
                <!-- SubTitle -->
                <h3 class="fw-700" style="margin: 2rem 0rem;">{{(modelPartner?.template?.subTitle ?? '')| translate}}</h3>
                <!-- Text 1 -->
                <h3 class="fs-16" *ngIf="modelPartner?.template?.description?.text1">{{(modelPartner?.template?.description?.text1 ?? '') | translate}}</h3>
                <!-- List -->
                <ul *ngIf="modelPartner?.template?.description?.list" class="partner-list">
                    <li *ngFor="let item of modelPartner?.template?.description?.list">
                        <h3 class="fs-16">{{item | translate}}</h3>
                    </li>
                </ul>
                <!-- Text 2 -->
                <h3 class="fs-16" *ngIf="modelPartner?.template?.description?.text2">{{(modelPartner?.template?.description?.text2 ?? '') | translate}}</h3>

                <!-- Bouton -->
                <div *ngIf="modelPartner?.template?.btnLabel && modelPartner?.template?.btnUrl" style="margin-top: 2rem;">
                    <moffi-ui-button (click)="openUrl(modelPartner?.template?.btnUrl ?? '')" buttonType="light" label="{{(modelPartner?.template?.btnLabel ?? '')| translate }}"></moffi-ui-button>
                </div>
            </div>
            <!-- Image -->
            <div class="page-horizontal-panel-img fadePanelHeader fade-panel">
                <img class="partner-img" src="assets/images/landings/marketplace/{{modelPartner?.key}}/template_image.webp" [alt]="modelPartner?.template?.alt"/>
            </div>
        </div>
    </div>
    <!-- Version mobile -->
    <div *ngIf="(isHandset$ | async)" class="page-panel" style="margin-top: 8rem;" >
        <!-- Image -->
        <div class="page-horizontal-panel-img fadePanelHeader fade-panel">
            <img src="assets/images/landings/marketplace/{{modelPartner?.key}}/template_image.webp" [alt]="modelPartner?.template?.alt"/>
        </div>
        <div class="page-horizontal-panel">
            <!-- Texte -->
            <div style="width: 95%;" class="partner-text fadePanelHeader fade-panel">
                <!-- Title-->
                <h2  style="margin-bottom: 2rem;">{{(modelPartner?.template?.title ?? '') | translate}}</h2>
                <!-- Text 1 -->
                <h3 class="fs-16" *ngIf="modelPartner?.template?.introduction?.text1">{{(modelPartner?.template?.introduction?.text1?? '') | translate}}</h3>
                <!-- List -->
                <ul *ngIf="modelPartner?.template?.introduction?.list" class="partner-list">
                    <li *ngFor="let item of modelPartner?.template?.introduction?.list">
                        <h3 class="fs-16">{{item | translate}}</h3>
                    </li>
                </ul>
                <!-- Text 2 -->
                <h3 class="fs-16" *ngIf="modelPartner?.template?.introduction?.text2">{{(modelPartner?.template?.introduction?.text2 ?? '') | translate}}</h3>
                <!-- SubTitle -->
                <h3 class="fw-700" style="margin: 2rem 0rem;">{{(modelPartner?.template?.subTitle ?? '')| translate}}</h3>
                <!-- Text 1 -->
                <h3 class="fs-16" *ngIf="modelPartner?.template?.description?.text1">{{(modelPartner?.template?.description?.text1 ?? '') | translate}}</h3>
                <!-- List -->
                <ul *ngIf="modelPartner?.template?.description?.list" class="partner-list">
                    <li *ngFor="let item of modelPartner?.template?.description?.list">
                        <h3 class="fs-16">{{item | translate}}</h3>
                    </li>
                </ul>
                <!-- Text 2 -->
                <h3 class="fs-16" *ngIf="modelPartner?.template?.description?.text2">{{(modelPartner?.template?.description?.text2 ?? '') | translate}}</h3>

                <!-- Bouton -->
                <div *ngIf="modelPartner?.template?.btnLabel && modelPartner?.template?.btnUrl" style="margin-top: 2rem;">
                    <moffi-ui-button (click)="openUrl(modelPartner?.template?.btnUrl ?? '')" buttonType="light" label="{{(modelPartner?.template?.btnLabel ?? '')| translate }}"></moffi-ui-button>
                </div>
            </div>
        </div>
    </div>


    <!----------------------- Formulaire ------------------------->
    <div class="page-panel" style="margin-bottom: 10rem;">
        <div class="page-horizontal-panel" [style.width]="!(isHandset$ | async) ? 'calc(min(110rem, 90vw))' : ''">
            <!-- Formulaire de Contact -->
            <div [ngClass]="!(isHandset$ | async) ? 'form-contact-container' : 'form-contact-container-mobile'" class="fade-panel fadePanelContact">
                <!-- Titre -->
                <h3 class="fs-21 fw-600">{{'contact.form.title' | translate}}</h3>
                <form class="form-contact" [formGroup]="form" (ngSubmit)="sendMail(formDirective)" #formDirective="ngForm">
                    <!-- Prénom & Nom-->
                    <moffi-ui-textbox style="width: 100%;" 
                        id="name" 
                        name="name"
                        [formControl]="getFormControl('name')"
                        type="text" 
                        width="100%"
                        [errorIcon]="true"
                        [customErrorMessages]="errorKeys"
                        label="{{'contact.form.name.label' | translate}}" 
                        placeholder="{{'contact.form.name.placeholder' | translate}}"
                        [required]="true"
                        [enabled]="true"
                        ngDefaultControl>
                    </moffi-ui-textbox>
                    
                    <div style="width: 100%;" [ngClass]="!(isHandset$ | async) ? 'form-group' : ''">
                        <!-- Email -->
                        <moffi-ui-textbox style="width: 50%;" 
                            id="mail" 
                            name="mail"
                            type="email" 
                            [errorIcon]="true"
                            [formControl]="getFormControl('mail')"
                            [customErrorMessages]="errorKeys"
                            label="{{'contact.form.mail.label' | translate}}" 
                            placeholder="{{'contact.form.mail.placeholder' | translate}}"
                            [required]="true"
                            width="100%"
                            ngDefaultControl>
                        </moffi-ui-textbox>
                        <!-- Téléphone -->
                        <moffi-ui-textbox style="width: 50%;" 
                            id="phone" 
                            name="phone"
                            type="text" 
                            [errorIcon]="true"
                            [formControl]="getFormControl('phone')"
                            [customErrorMessages]="errorKeys"
                            label="{{'contact.form.phone.label' | translate}}" 
                            placeholder="{{'contact.form.phone.placeholder' | translate}}"
                            [required]="true"
                            initialValue=""
                            width="100%"
                            ngDefaultControl>
                        </moffi-ui-textbox>
                    </div>
                
                    
                    <!-- Taille de la compagnie -->
                    <moffi-ui-combobox style="width: 100%;" 
                                id="employees" 
                                name="employees"
                                label="{{'contact.form.employees.label' | translate}}"
                                placeholder="{{'contact.form.employees.placeholder' | translate}}"
                                [required]="true"
                                [errorIcon]="true"
                                [formControl]="getFormControl('employees')"
                                [customErrorMessages]="errorKeys"
                                width="100%"
                                [options]="employees"
                                ngDefaultControl>
                    </moffi-ui-combobox>
                    <!-- Projet -->
                    <moffi-ui-combobox style="width: 100%;" 
                                id="interests" 
                                name="interests"
                                label="{{'contact.form.interest.label' | translate}}"
                                placeholder="{{'contact.form.interest.placeholder' | translate}}"
                                [required]="true"
                                [errorIcon]="true"
                                width="100%"
                                [formControl]="getFormControl('interests')"
                                [customErrorMessages]="errorKeys"
                                [multipleEnabled]="true"
                                [options]="projects"
                                ngDefaultControl>
                    </moffi-ui-combobox>

                    <!-- Message -->
                    <moffi-ui-textarea style="width: 100%;" 
                        id="message" 
                        name="message"
                        label="{{'contact.form.message.label' | translate}}" 
                        placeholder="{{'contact.form.message.placeholder' | translate}}" 
                        [required]="true"
                        [minLength]="2"
                        resize="none"
                        [errorIcon]="true"
                        [formControl]="getFormControl('message')"
                        [customErrorMessages]="errorKeys"
                        [autosizeEnabled]="true"
                        width="100%"
                        height="10rem"
                        ngDefaultControl>
                    </moffi-ui-textarea>

                    <!-- Checkbox GDPR -->
                    <div style="margin: 1rem 0rem;">
                        <moffi-ui-checkbox 
                            id="gdpr" 
                            name="gdpr" 
                            [formControl]="getFormControl('gdpr')"
                            [customErrorMessages]="errorKeys"
                            label="{{'contact.form.gdpr.text' | translate}}*"
                            width="100%">
                        </moffi-ui-checkbox>
                    </div>
                    

                    <!-- Captcha -->
                    <div class="captcha-container">
                        <re-captcha id="captcha" name="captcha" formControlName="captcha" [formControl]="getFormControl('captcha')" required></re-captcha>
                        <div class="captcha-error" *ngIf="!form.value.captcha && captchaError">
                            <mat-icon style="margin-left: 1rem;">error_outline</mat-icon>
                            {{'contact.form.name.error.mandatory' | translate}}
                        </div>
                    </div>
                    

                    <div class="fadePanel">
                        <moffi-ui-button buttonType="light"  type="submit" label="{{'contact.form.button' | translate}}" srcIcon="/assets/images/icon/icon_arrow_button.svg"></moffi-ui-button>
                    </div>
                </form>
            </div>

            <!-- Texte -->
            <div class="page-horizontal-panel-item">
                <img class="fade-panel fadePanelContact" src="assets/images/landings/page/hands/hand_contact_partner.svg" alt="icon_hand_contact"/>
                <h2 class="fade-panel fadePanelContact" appColoredText [textColor]="'#074DCE'" textKey="marketplace.partner.title"></h2>
                <ul class="fade-panel fadePanelContact">
                    <li>
                        <mat-icon style="color:#A1ABE2" fontIcon="done"></mat-icon>
                        <h3 style="text-align: left;" [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-item-text' : ''">{{'marketplace.partner.text1' | translate}}</h3>
                    </li>
                    <li>
                        <mat-icon style="color:#A1ABE2" fontIcon="done"></mat-icon>
                        <h3 style="text-align: left;" [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-item-text' : ''">{{'marketplace.partner.text2' | translate}}</h3>
                    </li>
                    <li>
                        <mat-icon style="color:#A1ABE2" fontIcon="done"></mat-icon>
                        <h3 style="text-align: left;" [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-item-text' : ''">{{'marketplace.partner.text3' | translate}}</h3>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</div>
