export interface ModelListPartners {
    defaultMostPopular: string[];
    partners: ModelPartner[];
}



export interface ModelPartner {
    key: string;
    name: string;
    description: string;
    tags: string[];
    tagsEnum: EPartnerTag[];
    template: {
        alt: string;
        title: string;
        introduction : {
            text1: string;
            list: string[];
            text2: string;
        };
        subTitle: string;
        description: {
            text1: string;
            list: string[];
            text2: string;
        };
        btnLabel: string;
        btnUrl: string;
    };
}

export enum EPartnerTag {
    ALL_CATEGORIES = "marketplace.category.all_categories",
    DISPLAY = 'marketplace.category.display',
    OFFICE_DESIGN = 'marketplace.category.office_design',
    WELLNESS = 'marketplace.category.wellness',
    SENSOR = 'marketplace.category.sensor',
    MAP = 'marketplace.category.map',
    COMMUNICATION = 'marketplace.category.communication',
    CHANGE_MANAGEMENT = 'marketplace.category.change_management',
    LOGIN = 'marketplace.category.login',
    ADVICE = 'marketplace.category.advice',
    ACCESS = 'marketplace.category.access',
    BUSINESS = 'marketplace.category.business',
    PARKING = 'marketplace.category.parking',
    MAINTENANCE = 'marketplace.category.maintenance',
    FURNITURE = 'marketplace.category.furniture',
    MOBILITY = 'marketplace.category.mobility',
    HRIS = 'marketplace.category.hris'
}




