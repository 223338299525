<div [ngClass]="!(isHandset$ | async) ? 'panel-list' : 'panel-list-mobile'">  
    <!-- Title --> 
    <h2>{{title}}</h2>
    <!-- List -->
    <div *ngFor="let item of listItem">
        <div class="panel-list-item">
            <mat-icon [ngClass]="item.titleClass" fontIcon="done"></mat-icon>
            <!-- Title -->
            <div [ngClass]="item.titleClass" class="panel-list-item-content fs-20">{{item.title}}
                <!-- Description-->
                <div style="max-width: 24rem;" class="fw-400 fs-16 primary-color">{{item.description}}</div>
            </div>
        </div>
    </div>
</div>
