import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  EEmployeeSize,
  EInterest,
  ModelMailDemo,
} from 'src/app/model/model-mail-demo';
import { ScrollAnimation } from 'src/app/utils/scroll-animations';
import { AbstractPageComponent } from '../abstract/page/abstract.page.component';


export function requireCheckValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value === true ? null : { requiredTrue: true };
  };
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss',
})
export class ContactComponent extends AbstractPageComponent {
  // ListBox store
  employees: any[] = [];
  projects: any[] = [];

  // Controllers
  phoneFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\+?(?:\d ?){6,14}\d$/),
  ]);
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  errorKeys = {};
  captchaError: boolean = false;

  //Form Data
  form: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    mail: this.emailFormControl,
    phone: this.phoneFormControl,
    employees: new FormControl('', Validators.required),
    interests: new FormControl([], Validators.required),
    message: new FormControl('', Validators.required),
    captcha: new FormControl(null, Validators.required),
    gdpr: new FormControl(false, requireCheckValidator())
  });

  override ngOnInit() {
    super.ngOnInit();

    // OnLangChange
    this.translateService.onLangChange.subscribe(() => {
      this.employees = [
        {
          key: EEmployeeSize.FiveThousandAndOneAndMore,
          value: this.getTranslation('contact.form.employees.menu.item5'),
        },
        {
          key: EEmployeeSize.OneThousandAndOneToFiveThousand,
          value: this.getTranslation('contact.form.employees.menu.item4'),
        },
        {
          key: EEmployeeSize.FiveHundredAndOnToOneThousand,
          value: this.getTranslation('contact.form.employees.menu.item3'),
        },
        {
          key: EEmployeeSize.TwoHundredAndOnToFiveHundred,
          value: this.getTranslation('contact.form.employees.menu.item2'),
        },
        {
          key: EEmployeeSize.OneToTwoHundred,
          value: this.getTranslation('contact.form.employees.menu.item1'),
        },
      ];
      // ComboBox Projet
      this.projects = [
        {
          key: EInterest.Desktop,
          value: this.getTranslation('contact.form.interest.menu.office'),
        },
        {
          key: EInterest.Meeting_room,
          value: this.getTranslation('contact.form.interest.menu.meeting'),
        },
        {
          key: EInterest.Home_office,
          value: this.getTranslation('contact.form.interest.menu.home_office'),
        },
        {
          key: EInterest.Parking,
          value: this.getTranslation('contact.form.interest.menu.parking'),
        },
        {
          key: EInterest.Other,
          value: this.getTranslation('contact.form.interest.menu.other'),
        },
      ];
      // Erreurs
      this.errorKeys = {
        email: this.getTranslation('contact.form.name.error.format'),
        pattern: this.getTranslation('contact.form.name.error.format'),
        required: this.getTranslation('contact.form.name.error.mandatory'),
        requiredTrue: this.getTranslation('contact.form.gdpr.error.mandatory')
      };
    });

    Promise.all([
      this.getTranslation('contact.form.employees.menu.item5'),
      this.getTranslation('contact.form.employees.menu.item4'),
      this.getTranslation('contact.form.employees.menu.item3'),
      this.getTranslation('contact.form.employees.menu.item2'),
      this.getTranslation('contact.form.employees.menu.item1'),
      this.getTranslation('contact.form.interest.menu.office'),
      this.getTranslation('contact.form.interest.menu.meeting'),
      this.getTranslation('contact.form.interest.menu.home_office'),
      this.getTranslation('contact.form.interest.menu.parking'),
      this.getTranslation('contact.form.interest.menu.other'),
      this.getTranslation('contact.form.name.error.format'),
      this.getTranslation('contact.form.name.error.mandatory'),
      this.getTranslation('contact.form.gdpr.error.mandatory')
    ])
      .then((translations) => {
        this.employees = [
          {
            key: EEmployeeSize.FiveThousandAndOneAndMore,
            value: translations[0],
          },
          {
            key: EEmployeeSize.OneThousandAndOneToFiveThousand,
            value: translations[1],
          },
          {
            key: EEmployeeSize.FiveHundredAndOnToOneThousand,
            value: translations[2],
          },
          {
            key: EEmployeeSize.TwoHundredAndOnToFiveHundred,
            value: translations[3],
          },
          { key: EEmployeeSize.OneToTwoHundred, value: translations[4] },
        ];
        this.projects = [
          { key: EInterest.Desktop, value: translations[5] },
          { key: EInterest.Meeting_room, value: translations[6] },
          { key: EInterest.Home_office, value: translations[7] },
          { key: EInterest.Parking, value: translations[8] },
          { key: EInterest.Other, value: translations[9] },
        ];
        this.errorKeys = {
          email: translations[10],
          pattern: translations[10],
          required: translations[11],
          requiredTrue: translations[12]
        };
      })
      .catch((error) => {
        console.error('Error fetching translations:', error);
      });
  }

  getFormControl(key: string): FormControl {
    return this.form.get(key) as FormControl;
  }

  setHeaderMetadatas() {
    this.title.setTitle(this.getTranslation('contact.metadata.title'));
    this.meta.updateTag({
      name: 'description',
      content: this.getTranslation('contact.metadata.description'),
    });
  }

  launchAnimations() {
    // Animations du header
    let fadePanelHeader: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelHeader.handleScroll(
      '.fadePanelHeader',
      'fadeIn 0.8s ease-in-out forwards',
      0.2,
      0
    );

    // Animation du panel Client
    let fadePanelClient: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelClient.handleScroll(
      '.fadePanelClient',
      'fadeIn 0.5s ease-in-out forwards',
      0.15,
      0
    );
  }

  /**
   * Envoi le mail de contact
   * @param formDirective
   */
  sendMail(formDirective: FormGroupDirective) {
    if (this.form.valid) {
      this.captchaError = false;

      // Si tous les champs sont valides
      let modelMailDemo: ModelMailDemo = {
        name: this.form.value.name,
        mail: this.form.value.mail,
        phone: this.form.value.phone,
        captcha: this.form.value.captcha,
        employees: this.form.value.employees,
        interests: this.form.value.interests,
        message: this.form.value.message,
        partner: undefined
      };

      this.apiService.sendMailDemo(modelMailDemo).subscribe({
        next: (result) => {
          this.resetFields(formDirective);
          this.redirectTo('message_sent');
        },
        error: (e) => {
          this.openSnackBar(
            "Une erreur s'est produite lors de l'envoie du message. Veuillez réessayer plus tard."
          );
        },
        complete: () => console.info('complete'),
      });
    } else {
      this.form.markAllAsTouched();
      let captchaErrors = this.form.get('captcha')?.errors;
      if (captchaErrors) {
        this.captchaError = true;
      }
    }
  }

  /**
   * Réinitialise tous les champs
   * @param formDirective
   */
  resetFields(formDirective: FormGroupDirective) {
    // Réinitialiser les valeurs
    this.form.reset();
    formDirective.resetForm();
  }
}
