import { Component } from '@angular/core';
import { ScrollAnimation } from 'src/app/utils/scroll-animations';
import { AbstractPageComponent } from '../abstract/page/abstract.page.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends AbstractPageComponent {
  // couleur du titre du panel Flexibilité
  colorUsecases: string = 'purple';
  colorUsecasesHex: string = '#B494FF';

  setHeaderMetadatas() {
    this.title.setTitle(this.getTranslation('home.metadata.title'));
    this.meta.updateTag({
      name: 'description',
      content: this.getTranslation('home.metadata.description'),
    });
  }

  public onColorUseCasesChanged(colors: string[]) {
    this.colorUsecases = colors[0];
    this.colorUsecasesHex = colors[1];
  }

  /** Animations */
  launchAnimations() {
    // Animations du header
    let fadePanelHeader: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelHeader.handleScroll(
      '.fadePanelHeader',
      'fadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );

    // // Animation des images du header
    // let popImgHeader: ScrollAnimation = new ScrollAnimation(this.document);
    // popImgHeader.handleScroll(
    //   '.headerImage',
    //   'scaleIn 2s ease-in-out forwards',
    //   0.5,
    //   0
    // );
    // let popImgHeader2: ScrollAnimation = new ScrollAnimation(this.document);
    // popImgHeader2.handleScroll(
    //   '.headerImagePic',
    //   'scaleOut 2s ease-in-out forwards',
    //   0.7,
    //   0
    // );

    // Animation du panel Client
    let fadePanelClient: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelClient.handleScroll(
      '.fadePanelClient',
      'fadeIn 0.5s ease-in-out forwards',
      0.15,
      0
    );

    // Animation du panel Flexibilité
    let fadePanelUsecases: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelUsecases.handleScroll(
      '.fadePanelUsecases',
      'fadeIn 0.8s ease-in-out forwards',
      0.2,
      0
    );

    // Animation du panel Stores
    let fadePanelStores: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelStores.handleScroll(
      '.fadePanelStores',
      'fadeIn 1s ease-in-out forwards',
      0.2,
      0
    );
    // Animation de l'image du panel Stores
    let fadePanelStoresImg1: ScrollAnimation = new ScrollAnimation(
      this.document
    );
    fadePanelStoresImg1.handleScroll(
      '.fadePanelStoresImg1',
      'scaleIn 1s ease-in-out forwards',
      0.5,
      0
    );
    let fadePanelStoresImg2: ScrollAnimation = new ScrollAnimation(
      this.document
    );
    fadePanelStoresImg2.handleScroll(
      '.fadePanelStoresImg2',
      'scaleIn 1s ease-in-out forwards',
      1,
      0
    );

    // Animation du panel Connexion aux outils
    let fadePanelTools: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelTools.handleScroll(
      '.fadePanelTools',
      'fadeIn 0.8s ease-in-out forwards',
      0.15,
      0
    );
    let fadePanelToolItem: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelToolItem.handleScroll(
      '.fadePanelToolItem',
      'scaleIn 1s ease-in-out forwards',
      0.5,
      0,
      true
    );

    // Animation du panel Performance et sécurité
    let fadePanelSecurity: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelSecurity.handleScroll(
      '.fadePanelSecurity',
      'fadeIn 0.5s ease-in-out forwards',
      0.2,
      0
    );

    // Animation du panel Accompagnement
    let fadePanelSupport: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelSupport.handleScroll(
      '.fadePanelSupport',
      'fadeIn 0.8s ease-in-out forwards',
      0.2,
      0
    );

    // Animation du panel Amabassadeur
    let fadePanelTestimony: ScrollAnimation = new ScrollAnimation(
      this.document
    );
    fadePanelTestimony.handleScroll(
      '.fadePanelTestimony',
      'fadeIn 0.8s ease-in-out forwards',
      0.2,
      0
    );
  }
}
