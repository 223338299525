import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-circle-item',
  templateUrl: './circle-item.component.html',
  styleUrl: './circle-item.component.scss'
})
export class CircleItemComponent {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 1150px)')
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  @Input() imgSrc!: string;
  @Input() imgAlt!: string;

  @Input() borderRadius? : string;

  @Input() isSquare? : boolean;


  constructor (private breakpointObserver: BreakpointObserver) {

  }

}
