<table class="simple-table" [style.padding]="padding">
  <caption></caption>
  <thead>
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
  </thead>
  <tbody>
    <tr *ngFor="let item of data">
      <ng-container *ngTemplateOutlet="columnTemplate; context: { $implicit: item }"></ng-container>
    </tr>
  </tbody>
</table>
