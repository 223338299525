<div class="circle-panel">
    <!-- Img Middle-->
    <div [ngClass]="!(isHandset$ | async) ? 'inner-circle' : 'inner-circle-mobile'">
        <img src="assets/images/logo/inner_circle_logo.svg" alt="inner_circle_logo"/>
    </div>
    <!-- Img Circle-->
    <img class="elipsis" src="assets/images/logo/ellipse.svg" alt="ellipse"/>

    <!-- Items -->
    <div class="items" style="--total: {{items.length}}">
        <div *ngFor="let item of items; let i = index" class="item" style="--i: {{i + 1}}">
            <ng-container>
                <app-circle-item [imgSrc]="item.imgSrc" [imgAlt]="item.imgAlt" [borderRadius]="item.borderRadius" [isSquare]="item.isSquare"></app-circle-item>
            </ng-container>
        </div>
    </div>
</div>
