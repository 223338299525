import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModelMailContact } from 'src/app/model/model-mail-contact';
import { ModelMailDemo } from 'src/app/model/model-mail-demo';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  /**
   * Souscrire à la newsletter
   * @param email 
   * @returns 
   */
  subscribeNewsLetter(email : string): Observable<any> {
    const apiUrl = environment.apiUrl;
    const body = { email: email };
    return this.http.post(`${apiUrl}/newsletterSubscription`, body)
  }

  /**
   * Envoi le message de demande de démo
   * @param message 
   * @returns 
   */
  sendMailDemo(message: ModelMailDemo) : Observable<any> {
    const apiUrl = environment.apiUrl;
    const body = message;
    return this.http.post(`${apiUrl}/demo`, body)
  }

  /**
   * Envoi le message pour Soumettre une idée ou Rejoindre l'équipe MOFFI
   * @param message 
   * @returns 
   */
  sendMailContact(message: ModelMailContact) : Observable<any> {
    const apiUrl = environment.apiUrl;
    const body = message;
    return this.http.post(`${apiUrl}/contact`, body)
  }
}
