import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  ContentChildren,
  ElementRef,
  Inject,
  Input,
  QueryList,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ScrollEventService } from 'src/app/service/scroll-event/scroll-event.service';
import { ScrollAnimation } from 'src/app/utils/scroll-animations';
import { StepComponent } from './step/step.component';

@Component({
  selector: 'app-panel-step',
  templateUrl: './steps-panel.component.html',
  styleUrls: ['./steps-panel.component.scss'],
})
export class StepsPanelComponent {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe('(max-width: 1150px)')
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  @ViewChild('container') container: ElementRef | undefined;
  items: any[] = [];

  // Liste des étapes
  @ContentChildren(StepComponent) steps!: QueryList<StepComponent>;

  // Orientation
  @Input() orientation: string = 'vertical';

  constructor(
    private breakpointObserver: BreakpointObserver,
    private el: ElementRef,
    private renderer: Renderer2,
    private scrollEventService: ScrollEventService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // Intercepte les évènement de scroll sur le main content
    this.scrollEventService.scrollEvent.subscribe((scrollPosY) => {
      this.launchAnimations();
    });
  }

  ngOnInit() {
    // Lance le calcul des positions une fois que toute la page a été chargée (images comprises)
    this.renderer.listen('window', 'load', () => {
      this.launchAnimations();
    });
  }

  ngAfterViewInit() {
    this.isHandset$.subscribe((isHandset) => {
      this.addLine(isHandset);
    });
  }

  private addLine(isHandset: boolean) {
    if (this.container) {
      if (this.isOrientationHorizontal() || isHandset) {
        const containerWidth = isHandset ? 16 : 26;
        const elementWidth = 0.7;
        const spacing = 0.5;

        const numberOfItems = Math.floor(
          containerWidth / (elementWidth + spacing)
        );

        for (let i = 0; i < numberOfItems; i++) {
          if (this.items.length <= i && this.items[i]) {
            this.items[i] = { left: `${i * (elementWidth + spacing)}rem` };
          } else {
            this.items.push({
              left: `${i * (elementWidth + spacing)}rem`,
            });
          }
        }
      } else {
        const containerHeight = 9;
        const elementHeight = 0.7;
        const spacing = 0.5;

        const numberOfItems = Math.floor(
          containerHeight / (elementHeight + spacing)
        );

        for (let i = 0; i < numberOfItems; i++) {
          if (this.items.length <= i && this.items[i]) {
            this.items[i] = { top: `${i * (elementHeight + spacing)}rem` };
          } else {
            this.items.push({
              top: `${i * (elementHeight + spacing)}rem`,
            });
          }
        }
      }
    }
  }

  /** Animations */
  private launchAnimations() {
    // Animations des étapes
    let fadePanelStep: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelStep.handleScroll(
      '.stepAnimation',
      'scaleIn 2s ease-in-out forwards',
      0,
      5
    );
    let fadePanelStepLeft: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelStepLeft.handleScroll(
      '.stepAnimationLeft',
      'scaleInLeft 1s ease-in-out forwards',
      0,
      5
    );
    let fadePanelStepRight: ScrollAnimation = new ScrollAnimation(
      this.document
    );
    fadePanelStepRight.handleScroll(
      '.stepAnimationRight',
      'scaleInRight 1s ease-in-out forwards',
      0,
      5
    );

    // Animations des étapes
    let fadePanelStepHorizontal: ScrollAnimation = new ScrollAnimation(
      this.document
    );
    fadePanelStepHorizontal.handleScroll(
      '.stepAnimationHorizontal',
      'scaleIn 2s ease-in-out forwards',
      0.5,
      0
    );
  }

  isOrientationVertical() {
    return this.orientation == 'vertical';
  }

  isOrientationHorizontal() {
    return this.orientation == 'horizontal';
  }
}
