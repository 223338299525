<div class="panel-card-large">
    <!-- Icone + texte -->
    <div class="panel-card-large-content">
        <img src="{{iconSrc}}" alt="{{iconAlt}}"/>
        <div *ngIf="title" class="fs-21 fw-600 primary-color">{{title}}</div>
        <div *ngIf="imgSeparator"><img src="{{imgSeparator}}" alt="separator"></div>
        <div class="fw-500 fs-20 f-style-italic primary-color">{{text}}</div>
    </div>
    <!-- Lien -->
    <a *ngIf="link" [style.color]="linkColor" href="{{link}}">{{'home.testimony.seeMore' | translate}}</a>

    <!-- Image + Sous-text-->
    <div *ngIf="!imgSrc" style="margin-top: 2rem;"></div>
    <div class="panel-card-large-subcontent">
        <div *ngIf="imgSrc" class="panel-card-large-subcontent-img">
            <img src="{{imgSrc}}" alt="{{imgAlt}}"/>
        </div>
        <div class="panel-card-large-subcontent-text fs-16 fw-600 primary-color">{{pTitle}}
            <span *ngIf="pText" class="fs-14 fw-400">{{pText}}</span>
        </div>
    </div>
    
</div>
