import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

export class ScrollAnimation {
  showPanels: boolean[] = [];

  constructor(@Inject(DOCUMENT) private document: Document) {}

  handleScroll(
    panelId: string,
    animationCss: string,
    delay: number,
    animationLaunch: number,
    isParentElement?: boolean
  ) {
    setTimeout(() => {
      const windowHeight = this.document.defaultView!.innerHeight;
      const elements = this.document.querySelectorAll(panelId);

      elements.forEach((element, index) => {
        let bounding = element.getBoundingClientRect();
        if (isParentElement && element.parentElement) {
          bounding = element.parentElement.getBoundingClientRect();
        }
        let topPosition = bounding.top;
        if (animationLaunch != 0) {
          topPosition = topPosition + windowHeight / animationLaunch;
        }
        // Vérifiez si l'élément est dans la fenêtre visible
        if (
          topPosition >= 0 &&
          topPosition <= windowHeight &&
          !this.showPanels[index]
        ) {
          this.showPanels[index] = true;
          // Chaque élément s'affiche dans l'ordre avec un délai
          const delayIndex = (index + 1) * delay;
          (element as HTMLElement).style.animation =
            animationCss + ' ' + delayIndex + 's';
        }
      });
    }, 100);
  }
}
