import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-panel-list-item',
  templateUrl: './panel-list-item.component.html',
  styleUrls: ['./panel-list-item.component.scss']
})
export class PanelListItemComponent {

  @Input() title!: string;
  @Input() titleClass!: string;

  @Input() description!: string;
}
