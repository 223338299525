import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { ScrollEventService } from 'src/app/service/scroll-event/scroll-event.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-usecases-panel',
  templateUrl: './usecases-panel.component.html',
  styleUrls: ['./usecases-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsecasesPanelComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 1150px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  private readonly COLOR_PURPLE: string = "purple";
  private readonly COLOR_PURPLE_HEX: string = "#B494FF";
  private readonly COLOR_PINK: string = "pink";
  private readonly COLOR_PINK_HEX: string = "#FF94F4";
  private readonly COLOR_GREEN: string = "green";
  private readonly COLOR_GREEN_HEX: string = "#45C6AA";
  private readonly COLOR_CYAN: string = "cyan";
  private readonly COLOR_CYAN_HEX: string = "#00ACD1";

  color!: string;
  colorHex!: string;

  currentLanguage: string;

  private languageChangeSubscription!: Subscription;

  @Output() colorChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

  animLaunched: boolean[] = [];

  constructor (private breakpointObserver: BreakpointObserver, private translateService: TranslateService, private scrollEventService : ScrollEventService) {
    this.currentLanguage = translateService.currentLang;

    this.languageChangeSubscription = this.translateService.onLangChange.subscribe(() => {
      this.updateCurrentLanguage();
    });

    // Intercepte les évènement de scroll sur le main content
    this.scrollEventService.scrollEvent.subscribe( (scrollPosY) => {
      this.launchAnimations(0);
    });
  }

  ngOnInit() {
    this.color = this.COLOR_PURPLE;
    this.colorHex = this.COLOR_PURPLE_HEX;
  }

  private updateCurrentLanguage() {
    this.currentLanguage = this.translateService.currentLang;
  }

  onTabChange(tab: MatTabChangeEvent) {
    this.changeColor(tab.index);
    this.launchAnimations(tab.index);
  }

  changeColor(tabIndex: number) {
      switch(tabIndex) {
        case 0: 
          this.color = this.COLOR_PURPLE;
          this.colorHex = this.COLOR_PURPLE_HEX;
          break;
        case 1:
          this.color = this.COLOR_PINK;
          this.colorHex = this.COLOR_PINK_HEX;
          break;
        case 2:
          this.color = this.COLOR_GREEN;
          this.colorHex = this.COLOR_GREEN_HEX;
          break;
        case 3:
          this.color = this.COLOR_CYAN;
          this.colorHex = this.COLOR_CYAN_HEX;
          break;
      }
      this.colorChanged.emit([this.color, this.colorHex]);
  }

  launchAnimations(tabIndex: number) {
    const windowHeight = this.isHandset$ ? window.innerHeight*2 : window.innerHeight;
    const panelId = "#usecasesImage" + tabIndex;
    const elements = document.querySelectorAll(panelId);
    const bounding  = elements[0]?.parentElement?.getBoundingClientRect();
    let topPosition = bounding?.top ? (bounding.top + (windowHeight/4)) : bounding?.top;
    
    if (topPosition && topPosition >= 0 && topPosition <= windowHeight && !this.animLaunched[tabIndex]) {
      elements.forEach((element, index) => {
        const delayIndex = (index + 1) * 0.3;
        (element as HTMLElement).style.animation = "scaleIn 2s ease-in-out forwards " + delayIndex + "s";
      });
      this.animLaunched[tabIndex] = true;
    }
  }

  ngOnDestroy() {
    // N'oubliez pas de vous désabonner pour éviter les fuites de mémoire
    if (this.languageChangeSubscription) {
      this.languageChangeSubscription.unsubscribe();
    }
  }
}
