import { Component } from '@angular/core';
import { AbstractPageComponent } from '../abstract/page/abstract.page.component';
import { environment } from 'src/environments/environment';
import { ScrollAnimation } from 'src/app/utils/scroll-animations';
import { EPartnerTag, ModelPartner } from 'src/app/model/model-partner';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ParamMap } from '@angular/router';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EEmployeeSize, EInterest, ModelMailDemo } from 'src/app/model/model-mail-demo';

export function requireCheckValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value === true ? null : { requiredTrue: true };
  };
}


@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrl: './partner.component.scss'
})
export class PartnerComponent extends AbstractPageComponent {

  // ListBox store
  employees: any[] = [];
  projects: any[] = [];

  // Controllers
  phoneFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\+?(?:\d ?){6,14}\d$/),
  ]);
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  errorKeys = {};
  captchaError: boolean = false;

  //Form Data
  form: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    mail: this.emailFormControl,
    phone: this.phoneFormControl,
    employees: new FormControl('', Validators.required),
    interests: new FormControl([], Validators.required),
    message: new FormControl('', Validators.required),
    captcha: new FormControl(null, Validators.required),
    gdpr: new FormControl(false, requireCheckValidator())
  });



  modelPartner?: ModelPartner;
  private subscription!: Subscription;

  override ngOnInit(): void {
    super.ngOnInit();
    this.subscription = this.activatedRoute.paramMap.pipe(
      switchMap((params: ParamMap) => {
        const id = params.get('id');
        if (id) {
          return this.partnersService.getPartnerById(id);
        }
        throw new Error('Partner ID is required');
      })
    ).subscribe(partner => {
      this.modelPartner = partner; 
      //Alimentation de la liste des tags
      this.modelPartner.tagsEnum = partner.tags.map(tag => EPartnerTag[tag as keyof typeof EPartnerTag]);
    }, error => {
      console.error('Failed to load partner data', error);
    });

    // OnLangChange
    this.translateService.onLangChange.subscribe(() => {
      this.employees = [
        {
          key: EEmployeeSize.FiveThousandAndOneAndMore,
          value: this.getTranslation('contact.form.employees.menu.item5'),
        },
        {
          key: EEmployeeSize.OneThousandAndOneToFiveThousand,
          value: this.getTranslation('contact.form.employees.menu.item4'),
        },
        {
          key: EEmployeeSize.FiveHundredAndOnToOneThousand,
          value: this.getTranslation('contact.form.employees.menu.item3'),
        },
        {
          key: EEmployeeSize.TwoHundredAndOnToFiveHundred,
          value: this.getTranslation('contact.form.employees.menu.item2'),
        },
        {
          key: EEmployeeSize.OneToTwoHundred,
          value: this.getTranslation('contact.form.employees.menu.item1'),
        },
      ];
      // ComboBox Projet
      this.projects = [
        {
          key: EInterest.Desktop,
          value: this.getTranslation('contact.form.interest.menu.office'),
        },
        {
          key: EInterest.Meeting_room,
          value: this.getTranslation('contact.form.interest.menu.meeting'),
        },
        {
          key: EInterest.Home_office,
          value: this.getTranslation('contact.form.interest.menu.home_office'),
        },
        {
          key: EInterest.Parking,
          value: this.getTranslation('contact.form.interest.menu.parking'),
        },
        {
          key: EInterest.Other,
          value: this.getTranslation('contact.form.interest.menu.other'),
        },
      ];
      // Erreurs
      this.errorKeys = {
        email: this.getTranslation('contact.form.name.error.format'),
        pattern: this.getTranslation('contact.form.name.error.format'),
        required: this.getTranslation('contact.form.name.error.mandatory'),
        requiredTrue: this.getTranslation('contact.form.gdpr.error.mandatory')
      };
    });

    Promise.all([
      this.getTranslation('contact.form.employees.menu.item5'),
      this.getTranslation('contact.form.employees.menu.item4'),
      this.getTranslation('contact.form.employees.menu.item3'),
      this.getTranslation('contact.form.employees.menu.item2'),
      this.getTranslation('contact.form.employees.menu.item1'),
      this.getTranslation('contact.form.interest.menu.office'),
      this.getTranslation('contact.form.interest.menu.meeting'),
      this.getTranslation('contact.form.interest.menu.home_office'),
      this.getTranslation('contact.form.interest.menu.parking'),
      this.getTranslation('contact.form.interest.menu.other'),
      this.getTranslation('contact.form.name.error.format'),
      this.getTranslation('contact.form.name.error.mandatory'),
      this.getTranslation('contact.form.gdpr.error.mandatory')
    ])
      .then((translations) => {
        this.employees = [
          {
            key: EEmployeeSize.FiveThousandAndOneAndMore,
            value: translations[0],
          },
          {
            key: EEmployeeSize.OneThousandAndOneToFiveThousand,
            value: translations[1],
          },
          {
            key: EEmployeeSize.FiveHundredAndOnToOneThousand,
            value: translations[2],
          },
          {
            key: EEmployeeSize.TwoHundredAndOnToFiveHundred,
            value: translations[3],
          },
          { key: EEmployeeSize.OneToTwoHundred, value: translations[4] },
        ];
        this.projects = [
          { key: EInterest.Desktop, value: translations[5] },
          { key: EInterest.Meeting_room, value: translations[6] },
          { key: EInterest.Home_office, value: translations[7] },
          { key: EInterest.Parking, value: translations[8] },
          { key: EInterest.Other, value: translations[9] },
        ];
        this.errorKeys = {
          email: translations[10],
          pattern: translations[10],
          required: translations[11],
          requiredTrue: translations[12]
        };
      })
      .catch((error) => {
        console.error('Error fetching translations:', error);
      });
  }

  getFormControl(key: string): FormControl {
    return this.form.get(key) as FormControl;
  }

  /**
   * Envoi le mail de contact
   * @param formDirective
   */
  sendMail(formDirective: FormGroupDirective) {
    if (this.form.valid) {
      this.captchaError = false;

      // Si tous les champs sont valides
      let modelMailDemo: ModelMailDemo = {
        name: this.form.value.name,
        mail: this.form.value.mail,
        phone: this.form.value.phone,
        captcha: this.form.value.captcha,
        employees: this.form.value.employees,
        interests: this.form.value.interests,
        message: this.form.value.message,
        partner: this.modelPartner?.name
      };

      this.apiService.sendMailDemo(modelMailDemo).subscribe({
        next: (result) => {
          this.resetFields(formDirective);
          this.redirectTo('message_sent');
        },
        error: (e) => {
          this.openSnackBar(
            "Une erreur s'est produite lors de l'envoie du message. Veuillez réessayer plus tard."
          );
        },
        complete: () => console.info('complete'),
      });
    } else {
      this.form.markAllAsTouched();
      let captchaErrors = this.form.get('captcha')?.errors;
      if (captchaErrors) {
        this.captchaError = true;
      }
    }
  }

   /**
   * Réinitialise tous les champs
   * @param formDirective
   */
   resetFields(formDirective: FormGroupDirective) {
    // Réinitialiser les valeurs
    this.form.reset();
    formDirective.resetForm();
  }
  
  override setHeaderMetadatas(): void {
    this.title.setTitle(this.getTranslation('marketplace.metadata.title'));
    this.meta.updateTag({name: 'description', content: this.getTranslation('marketplace.metadata.description'),});
  
    document
      ?.getElementById('alternate-fr-fr')
      ?.setAttribute('href', environment.frontUrl + '/fr/contact');
  }

  
  override launchAnimations(): void {
    // Animations du header
    let fadePanelHeader: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelHeader.handleScroll('.fadePanelHeader','fadeIn 0.5s ease-in-out forwards',0.2,0);

    // Animations du formulaire
    let fadePanelContact: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelContact.handleScroll('.fadePanelContact','fadeIn 0.5s ease-in-out forwards',0.2, 0);

    
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe(); 
    }
  }

}
