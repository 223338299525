import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-offer-item',
  templateUrl: './offer-item.component.html',
  styleUrl: './offer-item.component.scss',
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      transition('void <=> *', animate('0.5s ease-out')),
    ])
  ]
})
export class OfferItemComponent {

  @Input() id?: string;
  @Input() type: 'light' | 'colored' | 'dark' = 'light';
  @Input() title?: string;
  @Input() target?: string;

  @Input() featuresTitle?: string;
  @Input() features: string[] = [];

  @Input() moreTitle?: string;
  @Input() moreDescription?: string;

  @Input() withButton: boolean = true;
  @Input() buttonLabel: string = "";

  @Output() onButtonClick: EventEmitter<string> = new EventEmitter<string>();

  
  selected: boolean = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 1150px)')
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor(private breakpointObserver: BreakpointObserver) {
  }

  setSelectedItem(value: boolean) {
    this.selected = value;
  }

  onClick() {
    this.onButtonClick.emit(this.id);
  }
  

}
