<div class="page-content">
    <!----------------------- Header ------------------------->
    <div [ngClass]="!(isHandset$ | async) ? 'page-header' : 'page-header-mobile'" class="page-panel">
      <!-- Image -->
      <div class="fadePanelHeader fade-panel header-images">
        <div style="position: relative">
          <!-- Image background -->
          <img 
            [ngClass]="!(isHandset$ | async) ? 'img-page' : ''"
            src="assets/images/landings/parking/header/{{currentLanguage}}/header.webp"
            alt="{{ 'parking.caption.alt' | translate }}"
          />
        </div>
      </div>
  
      <!-- Titre -->
      <div class="page-panel" style="margin-top: 0rem">
        <div class="page-panel-title">
          <h1
            class="fadePanelHeader fade-panel"
            appColoredText
            [textColor]="'#074DCE'"
            textKey="parking.caption.title"
          ></h1>
          <h4 class="fadePanelHeader fade-panel">{{'parking.caption.description' | translate }}</h4>
        </div>
        <!-- Bouton réserver une démo -->
        <div class="fadePanelHeader fade-panel" style="margin-top: 1rem">
          <moffi-ui-button
            (eventClick)="redirectTo('contact')"
            buttonType="light"
            label="{{ 'parking.caption.button' | translate }}"
          ></moffi-ui-button>
        </div>
      </div>
    </div>
  
    <!----------------------- Clients ------------------------->
    <app-list-client
      style="margin-top: 6rem;"
      class="page-panel"
      title="{{ 'parking.clients.description' | translate }}"
      [clients]="clientsParking"
    ></app-list-client>

    <!----------------------- Bénéfices ------------------------->
    <div class="page-panel">
        <div class="page-horizontal-panel" [ngClass]="!(isHandset$ | async) ? 'benefits-panel' : 'benefits-panel-mobile'">
        <!-- Partage des places de parking -->
        <div class="page-panel-title fade-panel fadePanelBenefices">
            <img src="assets/images/landings/page/hands/hand_benefits_parking.svg" alt="icon_hand_flex" />
            <h3 appColoredText [textColor]="'#074DCE'" textKey="parking.benefits.text1"></h3>
        </div>
        <!-- Améliorer la qualité de vie -->
        <div class="page-panel-title fade-panel fadePanelBenefices">
            <img src="assets/images/landings/page/hands/hand_benefits_life_quality.svg" alt="icon_hand_flex" />
            <h3 appColoredText [textColor]="'#074DCE'" textKey="parking.benefits.text2"></h3>
        </div>
        <!-- Optimisez votre temps et votre budget -->
        <div class="page-panel-title fade-panel fadePanelBenefices">
            <img src="assets/images/landings/page/hands/hand_benefits_time.svg" alt="icon_hand_flex" />
            <h3 appColoredText [textColor]="'#074DCE'" textKey="parking.benefits.text3"></h3>
        </div>
        </div>
    </div>
  

    <!----------------------- Suivez l'occupation de votre parking ------------------------->
    <div class="page-panel">
        <div class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fadePanelVision fade-panel page-horizontal-panel-item">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="parking.stats.title"></h2>
                <h3 class="page-horizontal-panel-item-text" [innerHTML]="'parking.stats.text' | translate"></h3>
            </div>
            <!-- Image -->
            <div
                class="fadePanelVision fade-panel"
                [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'"
                style="position: relative; "
                [style]="!(isHandset$ |async) ? 'width : 34.5%;' : ''">
                <img 
                src="assets/images/landings/parking/stats/{{ currentLanguage }}/stats.webp"
                alt="{{ 'parking.stats.alt' | translate }}"
                />
            </div>
        </div>
    </div>


    <!----------------------- Contrôlez tous vos accès depuis un seul outil ------------------------->
    <div class="page-panel">
        <!-- Version desktop -->
        <div *ngIf="!(isHandset$ | async)" class="page-horizontal-panel" style="gap: 8rem">
            <!-- Image -->
            <div
                class="fadePanelVision fade-panel"
                [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'"
                style="position: relative; width : 34.5%;">
                <img 
                src="assets/images/landings/parking/tool/{{ currentLanguage }}/tool.webp"
                alt="{{ 'parking.tool.alt' | translate }}"
                />
            </div>
            <!-- Texte -->
            <div class="fadePanelVision fade-panel page-horizontal-panel-item">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="parking.tool.title"></h2>
                <h3 class="page-horizontal-panel-item-text" [innerHTML]="'parking.tool.text' | translate"></h3>
            </div>
        </div>
        <!-- Version mobile -->
        <div *ngIf="isHandset$ | async" class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fadePanelVision fade-panel page-horizontal-panel-item">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="parking.tool.title"></h2>
                <h3 class="page-horizontal-panel-item-text" [innerHTML]="'parking.tool.text' | translate"></h3>
            </div>
            <!-- Image -->
            <div
                class="fadePanelVision fade-panel"
                [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'"
                style="position: relative;">
                <img 
                src="assets/images/landings/parking/tool/{{ currentLanguage }}/tool.webp"
                alt="{{ 'parking.tool.alt' | translate }}"
                />
            </div>
        </div>
    </div>

    <!----------------------- Favorisez la mobilité durable de vos employés ------------------------->
    <div class="page-panel">
        <div class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fadePanelVision fade-panel page-horizontal-panel-item">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="parking.sustainability.title"></h2>
                <h3 class="page-horizontal-panel-item-text" [innerHTML]="'parking.sustainability.text' | translate"></h3>
            </div>
            <!-- Image -->
            <div
                class="fadePanelVision fade-panel"
                [ngClass]="!(isHandset$ | async) ? 'page-horizontal-panel-img' : 'page-horizontal-panel-img-margin'"
                style="position: relative; "
                [style]="!(isHandset$ |async) ? 'width : 34.5%;' : ''">
                <img 
                src="assets/images/landings/parking/sustainability/{{ currentLanguage }}/sustainability.webp"
                alt="{{ 'parking.sustainability.alt' | translate }}"
                />
            </div>
        </div>
    </div>


    <!----------------------- Gérer mon parking ? Plus jamais sans MOFFI ------------------------->
    <div class="page-panel">
        <!-- title -->
        <div class="page-panel-title">
        <div class="fadePanelAssets fade-panel">
            <img src="assets/images/landings/page/hands/hand_assets_parking.svg" alt="icon_hand_flex" />
            <h2 appColoredText [textColor]="'#074DCE'" textKey="parking.assets.title"></h2>
        </div>
        </div>
        <!-- Tableau Version Desktop -->
        <div *ngIf="!(isHandset$ | async)" class="fadePanelAssets fade-panel" style="margin-top: 3rem; max-width: 95%">
        <app-simple-table [data]="assetsTableData">
            <!-- Header -->
            <ng-template let-item #headerTemplate>
            <tr>
                <th class="assets-table-header" id="asset"></th>
                <th class="assets-table-header assets-table-border-left" id="withoutMoffi">
                {{ 'parking.assets.without_moffi.title' | translate }}
                </th>
                <th class="assets-table-header assets-table-border-left" id="withMoffi">
                {{ 'parking.assets.with_moffi.title' | translate }}
                </th>
            </tr>
            </ng-template>
            <!-- Colonnes -->
            <ng-template let-item #columnTemplate>
            <td
                class="assets-table-td assets-table-td-asset"
                appColoredText
                [textColor]="'#074DCE'"
                [textKey]="item.assetKey"
            ></td>
            <td class="assets-table-td assets-table-border-left">
                <mat-icon *ngIf="!item.withoutMoffi" style="color: #c5cbec" fontIcon="close"></mat-icon>
                <mat-icon *ngIf="item.withoutMoffi" style="color: #45c6aa" fontIcon="done"></mat-icon>
            </td>
            <td class="assets-table-td assets-table-border-left">
                <mat-icon *ngIf="!item.withMoffi" style="color: #c5cbec" fontIcon="close"></mat-icon>
                <mat-icon *ngIf="item.withMoffi" style="color: #45c6aa" fontIcon="done"></mat-icon>
            </td>
            </ng-template>
        </app-simple-table>
        </div>
        <!-- Tableau Version Mobile -->
        <div *ngIf="isHandset$ | async" class="fadePanelAssets fade-panel" style="margin-top: 3rem; max-width: 95%">
        <app-simple-table [padding]="'1rem'" [data]="assetsTableDataMobile">
            <!-- Header -->
            <ng-template let-item #headerTemplate>
            <tr>
                <th class="assets-table-header-mobile" id="withoutMoffi">
                {{ 'parking.assets.without_moffi.title' | translate }}
                </th>
                <th class="assets-table-header-mobile" id="withMoffi">
                {{ 'parking.assets.with_moffi.title' | translate }}
                </th>
            </tr>
            </ng-template>
            <!-- Colonnes -->
            <ng-template let-item #columnTemplate>
            <td
                *ngIf="item.assetKey"
                colspan="2"
                class="assets-table-td-mobile"
                appColoredText
                [textColor]="'#074DCE'"
                [textKey]="item.assetKey"
            ></td>

            <td
                *ngIf="!item.assetKey"
                [ngClass]="!item.isLastElement ? 'assets-table-border-bottom' : ''"
                class="assets-table-td-mobile"
            >
                <mat-icon *ngIf="!item.withoutMoffi" style="color: #c5cbec" fontIcon="close"></mat-icon>
                <mat-icon *ngIf="item.withoutMoffi" style="color: #45c6aa" fontIcon="done"></mat-icon>
            </td>
            <td
                *ngIf="!item.assetKey"
                [ngClass]="!item.isLastElement ? 'assets-table-border-bottom' : ''"
                class="assets-table-td-mobile"
            >
                <mat-icon *ngIf="!item.withMoffi" style="color: #c5cbec" fontIcon="close"></mat-icon>
                <mat-icon *ngIf="item.withMoffi" style="color: #45c6aa" fontIcon="done"></mat-icon>
            </td>
            </ng-template>
        </app-simple-table>
        </div>
    </div>

    <!----------------------- Bandeau démo ------------------------->
    <div style="margin-top: 8rem" class="page-demo-panel">
        <!-- Background -->
        <div class="page-demo-background">
        <div style="width: 50rem; height: 40rem; top: -61%; left: 76%" class="gradient-circle blue-gradient"></div>
        <div style="top: -8%; left: 80%; transform: rotate(140deg); opacity: 0.9" class="gradient-small-circle pink-gradient"></div>
        <div style="top: -13%; left: 82%; transform: rotate(140deg)" class="gradient-small-circle yellow-gradient"></div>
        <div style="width: 50rem; height: 40rem; top: 40%; left: 65%" class="gradient-circle blue-gradient"></div>
        <div style="top: 45%; left: 72%; opacity: 0.8" class="gradient-small-circle green-gradient"></div>
        <div style="width: 50rem; height: 50rem; top: 42%; left: 85%; opacity: 0.7" class="gradient-circle purple-gradient"></div>
        </div>

        <!-- title -->
        <div class="page-panel-title">
        <img src="assets/images/landings/page/hands/hand_demo.svg" alt="icon_hand_demo" />
        <h2 class="dark-color">{{ 'parking.cta.title' | translate }}</h2>
        <h4 class="dark-color" appColoredText [textColor]="'#A1ABE2'" textKey="parking.cta.description"></h4>
        </div>

        <!-- Bouton démo -->
        <moffi-ui-button
        (eventClick)="redirectTo('contact')"
        buttonType="dark"
        label="{{ 'parking.cta.button' | translate }}"
        ></moffi-ui-button>
    </div>
   
  </div>
  