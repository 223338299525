

<div *ngIf="!isSquare" [ngClass]="!(isHandset$ | async) ? 'circle-item' : 'circle-item-mobile'" class="fs-21 fw-500 primary-color" [style.border-radius]="borderRadius">
    <img src="{{imgSrc}}" alt="{{imgAlt}}"/>
</div>

<div *ngIf="isSquare" [ngClass]="!(isHandset$ | async) ? 'square-item' : 'square-item-mobile'" class="fs-21 fw-500 primary-color" [style.border-radius]="borderRadius">
    <img src="{{imgSrc}}" alt="{{imgAlt}}"/>
</div>


