import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ModelListPartners, ModelPartner } from 'src/app/model/model-partner';

@Injectable({
  providedIn: 'root'
})
export class PartnersService {

  private partnersUrl = 'assets/data/partners.json';

  constructor(private http: HttpClient) { }

  getListPartners(): Observable<ModelListPartners> {
    return this.http.get<ModelListPartners>(this.partnersUrl)
  }

  getPartnerById(key: string): Observable<ModelPartner> {
    return this.http.get<ModelListPartners>(this.partnersUrl)
    .pipe(
      map(response => {
        const partners = response.partners;
        if (!partners) {
          throw new Error('Partners data is malformed or missing');
        }
        const partner = partners.find(partner => partner.key === key);
        if (!partner) {
          throw new Error(`No partner found with key ${key}`);
        }
        return partner;
      })
    );
  }
}
