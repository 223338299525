<!-- Tab Panel -->
<div class="usecases-tabpanel">
    <div class="gradient-circle {{color}}-gradient"></div>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" fitInkBarToContent (selectedTabChange)="onTabChange($event);">
        <!-- Panel Office Manager -->
        <mat-tab labelClass="ink-bar-purple" label="{{'home.usecases.officeManager.title' | translate}}">
            <!-- Liste -->
            <div [ngClass]="!(isHandset$ | async) ? 'usecases-panel-item' : 'usecases-panel-item-mobile'">
                <app-panel-list style="max-width: 90%;" title="{{'home.usecases.officeManager.title' | translate}}">
                    <!-- Rentabilisez vos bureaux et salles de réunion -->
                    <app-panel-list-item title="{{'home.usecases.officeManager.profit.title' | translate}}"
                    titleClass="{{color}}-panel-list"
                    description="{{'home.usecases.officeManager.profit.description' | translate}}"></app-panel-list-item>
                    <!-- Automatisez les tâches récurrentes -->
                    <app-panel-list-item title="{{'home.usecases.officeManager.automation.title' | translate}}"
                    titleClass="{{color}}-panel-list"
                    description="{{'home.usecases.officeManager.automation.description' | translate}}"></app-panel-list-item>
                    <!-- Sachez qui est dans votre bâtiment -->
                    <app-panel-list-item title="{{'home.usecases.officeManager.building.title' | translate}}"
                    titleClass="{{color}}-panel-list"
                    description="{{'home.usecases.officeManager.building.description' | translate}}"></app-panel-list-item>
                </app-panel-list>
                <!-- Illustrations -->
                <div [ngClass]="!(isHandset$ | async) ? 'usecases-images' : 'usecases-images-mobile'">
                    <!-- Image Date -->
                    <div id="usecasesImage0" class="usecases-image office-manager-date">
                        <img src="assets/images/landings/home/usecases/{{currentLanguage}}/office_manager/date.webp"
                        alt="{{'home.usecases.officeManager.image_time.alt' | translate}}"/>
                    </div>
                    <!-- Image State -->
                    <div id="usecasesImage0" class="usecases-image office-manager-state">
                        <img src="assets/images/landings/home/usecases/{{currentLanguage}}/office_manager/state.webp"
                        alt="{{'home.usecases.officeManager.image_space.alt' | translate}}"/>
                    </div>
                    <!-- Image Background-->
                    <img src="assets/images/landings/home/usecases/{{currentLanguage}}/office_manager/background.webp"
                    alt="{{'home.usecases.officeManager.image.alt' | translate}}"/>
                </div>
            </div>
        </mat-tab>

        <!-- Panel Ressources Humaines-->
        <mat-tab labelClass="ink-bar-pink" label="{{'home.usecases.humanResources.title' | translate}}">
            <!-- Liste -->
            <div [ngClass]="!(isHandset$ | async) ? 'usecases-panel-item' : 'usecases-panel-item-mobile'">
                <app-panel-list style="max-width: 90%;" title="{{'home.usecases.humanResources.title' | translate}}">
                    <!-- Configurez vos règles de télétravail -->
                    <app-panel-list-item title="{{'home.usecases.humanResources.rules.title' | translate}}"
                    titleClass="{{color}}-panel-list"
                    description="{{'home.usecases.humanResources.rules.description' | translate}}"></app-panel-list-item>
                    <!-- Synchronisez MOFFI à votre SIRH -->
                    <app-panel-list-item title="{{'home.usecases.humanResources.sync.title' | translate}}"
                    titleClass="{{color}}-panel-list"
                    description="{{'home.usecases.humanResources.sync.description' | translate}}"></app-panel-list-item>
                    <!-- Exporter vos données -->
                    <app-panel-list-item title="{{'home.usecases.humanResources.export.title' | translate}}"
                    titleClass="{{color}}-panel-list"
                    description="{{'home.usecases.humanResources.export.description' | translate}}"></app-panel-list-item>
                </app-panel-list>
                <!-- Illustrations -->
                <div [ngClass]="!(isHandset$ | async) ? 'usecases-images' : 'usecases-images-mobile'">
                    <!-- Image Logo -->
                    <div id="usecasesImage1" class="usecases-image human-resources-logo">
                        <img src="assets/images/landings/home/usecases/{{currentLanguage}}/human_resources/logo.webp"
                        alt="{{'home.usecases.humanResources.image_logo.alt' | translate}}"/>
                    </div>
                    <!-- Image Users -->
                    <div id="usecasesImage1" class="usecases-image human-resources-users">
                        <img src="assets/images/landings/home/usecases/{{currentLanguage}}/human_resources/users.webp"
                        alt="{{'home.usecases.humanResources.image_users.alt' | translate}}"/>
                    </div>
                    <!-- Image Background-->
                    <img src="assets/images/landings/home/usecases/{{currentLanguage}}/human_resources/background.webp"
                    alt="{{'home.usecases.humanResources.image.alt' | translate}}"/>
                </div>
            </div>
        </mat-tab>

        <!-- Panel Manager -->
        <mat-tab labelClass="ink-bar-green" label="{{'home.usecases.manager.title' | translate}}">
            <!-- Liste -->
            <div [ngClass]="!(isHandset$ | async) ? 'usecases-panel-item' : 'usecases-panel-item-mobile'">
                <app-panel-list style="max-width: 90%;" title="{{'home.usecases.manager.title' | translate}}">
                    <!-- Favorisez la collaboration -->
                    <app-panel-list-item title="{{'home.usecases.manager.cooperation.title' | translate}}"
                    titleClass="{{color}}-panel-list"
                    description="{{'home.usecases.manager.cooperation.description' | translate}}"></app-panel-list-item>
                    <!-- Visualisez qui travaille où -->
                    <app-panel-list-item title="{{'home.usecases.manager.location.title' | translate}}"
                    titleClass="{{color}}-panel-list"
                    description="{{'home.usecases.manager.location.description' | translate}}"></app-panel-list-item>
                    <!-- Analysez le data -->
                    <app-panel-list-item title="{{'home.usecases.manager.data.title' | translate}}"
                    titleClass="{{color}}-panel-list"
                    description="{{'home.usecases.manager.data.description' | translate}}"></app-panel-list-item>
                </app-panel-list>
                <!-- Illustrations -->
                <div [ngClass]="!(isHandset$ | async) ? 'usecases-images' : 'usecases-images-mobile'">
                    <!-- Image Statistic -->
                    <div id="usecasesImage2" class="usecases-image manager-statistic">
                        <img src="assets/images/landings/home/usecases/{{currentLanguage}}/manager/statistic.webp"
                        alt="{{'home.usecases.manager.image_stats.alt' | translate}}"/>
                    </div>
                    <!-- Image State -->
                    <div id="usecasesImage2" class="usecases-image manager-state">
                        <img src="assets/images/landings/home/usecases/{{currentLanguage}}/manager/state.webp"
                        alt="{{'home.usecases.manager.image_type.alt' | translate}}"/>
                    </div>
                    <!-- Image Background-->
                    <img src="assets/images/landings/home/usecases/{{currentLanguage}}/manager/background.webp"
                    alt="{{'home.usecases.manager.image.alt' | translate}}"/>
                </div>
            </div>
        </mat-tab>

        <!-- Panel Collaborateur -->
        <mat-tab labelClass="ink-bar-cyan" label="{{'home.usecases.employee.title' | translate}}">
            <!-- Liste -->
            <div [ngClass]="!(isHandset$ | async) ? 'usecases-panel-item' : 'usecases-panel-item-mobile'">
                <app-panel-list style="max-width: 90%;" title="{{'home.usecases.employee.title' | translate}}">
                    <!-- Planifiez votre semaine -->
                    <app-panel-list-item title="{{'home.usecases.employee.plan.title' | translate}}"
                    titleClass="{{color}}-panel-list"
                    description="{{'home.usecases.employee.plan.description' | translate}}"></app-panel-list-item>
                    <!-- Visualisez qui est au bureau -->
                    <app-panel-list-item title="{{'home.usecases.employee.office.title' | translate}}"
                    titleClass="{{color}}-panel-list"
                    description="{{'home.usecases.employee.office.description' | translate}}"></app-panel-list-item>
                    <!-- Choisissez votre place -->
                    <app-panel-list-item title="{{'home.usecases.employee.place.title' | translate}}"
                    titleClass="{{color}}-panel-list"
                    description="{{'home.usecases.employee.place.description' | translate}}"></app-panel-list-item>
                </app-panel-list>
                <!-- Illustrations -->
                <div [ngClass]="!(isHandset$ | async) ? 'usecases-images' : 'usecases-images-mobile'">
                    <!-- Image Desk -->
                    <div id="usecasesImage3" class="usecases-image coworker-desk">
                        <img src="assets/images/landings/home/usecases/{{currentLanguage}}/coworker/desk.webp"
                        alt="{{'home.usecases.employee.image.desk.alt' | translate}}"/>
                    </div>
                    <!-- Image Absence -->
                    <div id="usecasesImage3" class="usecases-image coworker-absence">
                        <img  src="assets/images/landings/home/usecases/{{currentLanguage}}/coworker/absence.webp"
                        alt="{{'home.usecases.employee.image.absence.alt' | translate}}"/>
                    </div>
                    <!-- Image Home Office -->
                    <div id="usecasesImage3" class="usecases-image coworker-remote">
                        <img  src="assets/images/landings/home/usecases/{{currentLanguage}}/coworker/home_office.webp"
                        alt="{{'home.usecases.employee.image.home_office.alt' | translate}}"/>
                    </div>
                    <!-- Image Business Meeting -->
                    <div id="usecasesImage3" class="usecases-image coworker-business-meeting">
                        <img  src="assets/images/landings/home/usecases/{{currentLanguage}}/coworker/business_meeting.webp"
                        alt="{{'home.usecases.employee.image.meeting.alt' | translate}}"/>
                    </div>
                    <!-- Image Background-->
                    <img src="assets/images/landings/home/usecases/{{currentLanguage}}/coworker/background.webp"
                    alt="{{'home.usecases.employee.image.main.alt' | translate}}"/>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
