import { Component, ContentChildren, QueryList } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CircleItemComponent } from './circle-item/circle-item.component';

@Component({
  selector: 'app-circle-panel',
  templateUrl: './circle-panel.component.html',
  styleUrl: './circle-panel.component.scss'
})
export class CirclePanelComponent {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 1150px)')
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

// Liste des item tool
@ContentChildren(CircleItemComponent) items!: QueryList<CircleItemComponent>;

constructor (private breakpointObserver: BreakpointObserver) {

}
}
