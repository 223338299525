<div class="page-content">
    <div class="page-panel" style="margin-bottom: 5rem; margin-top: 8rem;">
        <div [ngClass]=" !(isHandset$ | async) ? 'message-container' : 'message-container-mobile'">
            <div class="message-header">
                <h3 class="message-title">{{'contact.message_sent.title' | translate}}</h3>
                <mat-icon style="color:#074DCE" fontIcon="done"></mat-icon>
            </div>
            <div class="line"></div>
            <h4 class="message-desc">{{'contact.message_sent.description' | translate}}</h4>
            <moffi-ui-button buttonType="light" label="{{'contact.message_sent.button' | translate}}" (eventClick)="redirectTo('home')"></moffi-ui-button>
        </div>
    </div>
</div>
