import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
  styleUrl: './article-card.component.scss'
})
export class ArticleCardComponent {

  @Input() key!: string;

  @Input() title!: string;
  
  @Input() text!: string;

  @Input() link!: string;


  openUrl(url: string) {
    window.open(url, '_blank');
  }
}
