import { Component } from '@angular/core';
import { AbstractPageComponent } from '../abstract/page/abstract.page.component';
import { ModelDefinition, ModelListDefinitions } from 'src/app/model/model-definition';
import { ScrollAnimation } from 'src/app/utils/scroll-animations';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrl: './glossary.component.scss'
})
export class GlossaryComponent extends AbstractPageComponent {
  listDefinitions!: ModelListDefinitions;
  groupedDefinitions: { [key: string]: ModelDefinition[] } = {};

  override ngOnInit() {
    super.ngOnInit();
    // Récupération des définitions
    this.definitionsService.getListDefinitions().subscribe((result) => {
      this.listDefinitions = result;
      this.filterDefinitions();
      // Filtre des définitions
      this.translateService.onLangChange.subscribe(() => {
        this.filterDefinitions();
      });
    });
    // Observer les changements dans isChildRoute$
    this.isChildRoute$.subscribe((value: boolean) => {
      if (!value) {
        this.launchAnimations();
        this.setHeaderMetadatas();
      }
    });
  }

  filterDefinitions(): void {
    this.groupedDefinitions = {};
    this.listDefinitions.definitions.forEach((def) => {
      const name = this.translateService.instant(def.name);
      const firstLetter = name.charAt(0).toUpperCase();

      if (!this.groupedDefinitions[firstLetter]) {
        this.groupedDefinitions[firstLetter] = [];
      }
      def.translatedName = name;
      this.groupedDefinitions[firstLetter].push(def);
    });
  }

  getSortedKeys(): string[] {
    return Object.keys(this.groupedDefinitions).sort((a, b) => a.localeCompare(b));
  }

  openDefinitionDetail(key: string) {
    this.redirectTo('glossary', '', key);
  }

  override setHeaderMetadatas(): void {
    this.title.setTitle(this.getTranslation('glossary.metadata.title'));
    this.meta.updateTag({ name: 'description', content: this.getTranslation('glossary.metadata.description') });

    document?.getElementById('alternate-fr-fr')?.setAttribute('href', environment.frontUrl + '/fr/contact');
  }

  override launchAnimations(): void {
    // Animations du header
    let fadePanelHeader: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelHeader.handleScroll('.fadePanelHeader', 'fadeIn 0.5s ease-in-out forwards', 0.3, 0);
  }
}
