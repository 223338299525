import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrl: './simple-table.component.scss'
})
export class SimpleTableComponent<T extends Record<string, any>> {
  
  @ContentChild('headerTemplate') headerTemplate!: TemplateRef<any>;
  @ContentChild('columnTemplate') columnTemplate!: TemplateRef<any>;


  @Input() data: T[] = [];
  @Input() padding?: string;


}
