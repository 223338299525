<div [ngClass]="{'tree-menu-list-item-selected': isOpen(), 'tree-menu-list-item': !isOpen()}">
    <div class="{{fontStyleTitle}} tree-menu-list-item-header" (click)="toggleSubMenu()">
        <div>{{title}}</div>
        <mat-icon style="font-size: 24px;" fontIcon="expand_more" *ngIf="!isOpen() && hasChildren()"></mat-icon>
        <mat-icon style="font-size: 24px;" fontIcon="expand_less" *ngIf="isOpen() && hasChildren()"></mat-icon>
    </div>

    <div class="tree-submenu-list" *ngIf="isOpen()">
        <div *ngIf="description" class="tree-submenu-item {{fontStyleDesc}}">
            <div class="tree-submenu-text">
                {{description}}
            </div>
        </div>
        <div class="tree-submenu-item" *ngFor="let submenuItem of submenuItems; let last = last" (click)="submenuItem.onClick()">
            <div class="tree-submenu-text" [ngClass]="{'tree-submenu-last-text': last}">
                <div style="display:flex;" [ngClass]=" submenuItem.isSelected ? submenuItem.fontStyleTitleSelected : submenuItem.fontStyleTitle">
                    <img alt="Icon submenu" style="margin-right: 0.5rem;" src="{{submenuItem.icon}}" *ngIf="(submenuItem.icon != null)"/>
                    {{submenuItem.title}}
                </div>
                <div style="opacity: 0.75;" class="{{submenuItem.fontStyleDesc}}">{{submenuItem.description}}</div>
            </div>
            <mat-icon *ngIf="submenuItem.withArrow" style="font-size: 24px; opacity: 0.75;" class="{{submenuItem.fontStyleDesc}}" fontIcon="trending_flat"></mat-icon>
        </div> 
    </div>


</div>
