import { Component } from '@angular/core';
import { ScrollAnimation } from 'src/app/utils/scroll-animations';
import { AbstractPageComponent } from '../abstract/page/abstract.page.component';

@Component({
  selector: 'app-remote',
  templateUrl: './remote.component.html',
  styleUrl: './remote.component.scss',
})
export class RemoteComponent extends AbstractPageComponent {
  assetsTableData: any[] = [
    {
      assetKey: 'remote.assets.visibility',
      withoutMoffi: false,
      withMoffi: true,
    },
    { assetKey: 'remote.assets.stats', withoutMoffi: false, withMoffi: true },
    {
      assetKey: 'remote.assets.calculation',
      withoutMoffi: false,
      withMoffi: true,
    },
    {
      assetKey: 'remote.assets.agreements',
      withoutMoffi: false,
      withMoffi: true,
    },
    { assetKey: 'remote.assets.social', withoutMoffi: false, withMoffi: true },
    {
      assetKey: 'remote.assets.double_entry',
      withoutMoffi: false,
      withMoffi: true,
    },
  ];

  assetsTableDataMobile: any[] = [
    { assetKey: 'remote.assets.visibility' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'remote.assets.stats' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'remote.assets.calculation' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'remote.assets.agreements' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'remote.assets.social' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'remote.assets.double_entry' },
    { assetKey: '', withoutMoffi: false, withMoffi: true, isLastElement: true },
  ];

  setHeaderMetadatas() {
    this.title.setTitle(this.getTranslation('remote.metadata.title'));
    this.meta.updateTag({
      name: 'description',
      content: this.getTranslation('remote.metadata.description'),
    });
  }

  launchAnimations() {
    // Animations du header
    let fadePanelHeader: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelHeader.handleScroll(
      '.fadePanelHeader',
      'fadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );

    // Animation des images du header
    let popImgHeader: ScrollAnimation = new ScrollAnimation(this.document);
    popImgHeader.handleScroll(
      '.headerImage',
      'scaleIn 2s ease-in-out forwards',
      0.5,
      0
    );

    // Animation du panel Client
    let fadePanelClient: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelClient.handleScroll(
      '.fadePanelClient',
      'fadeIn 0.5s ease-in-out forwards',
      0.15,
      0
    );

    // Animation du panel Vision du télétravail
    this.createAnimationHorizontalPanelDelay(
      '.fadePanelVision',
      0.2,
      0.2,
      2,
      true
    );
    // Animation du panel Atout
    this.createAnimationHorizontalPanelDelay('.fadePanelHc', 0.2, 0.2, 2);
    // Animation du déplacement du curseur
    let cursorAnim: ScrollAnimation = new ScrollAnimation(this.document);
    cursorAnim.handleScroll(
      '.hcImgCursor',
      'mooveCursor 1s ease-in-out forwards',
      0.5,
      0
    );
    // Animation du clic sur le bouton
    let popImgButton: ScrollAnimation = new ScrollAnimation(this.document);
    popImgButton.handleScroll(
      '.fadePanelHcImgClick',
      'clicButton 1s ease-in-out forwards',
      1.5,
      0
    );
    // Animation de l'apparition du panel Workspace
    let popImgWorkspance: ScrollAnimation = new ScrollAnimation(this.document);
    popImgWorkspance.handleScroll(
      '.fadePanelHcImgWorkspace',
      'scaleIn 1s ease-in-out forwards',
      1.7,
      0
    );

    // Animation du panel Suivi du télétravail
    this.createAnimationHorizontalPanel('.fadePanelManage');
    // Animation du panel Suivi du télétravail
    this.createAnimationHorizontalPanel('.fadePanelValue');

    // Animation du panel Assets
    let fadePanelAssets: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelAssets.handleScroll(
      '.fadePanelAssets',
      'fadeIn 0.8s ease-in-out forwards',
      0.2,
      0
    );

    // Animation du panel Hris
    let fadePanelHris: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelHris.handleScroll(
      '.fadePanelHris',
      'fadeIn 0.8s ease-in-out forwards',
      0.5,
      0
    );

    // Animation du panel Accompagnement
    let fadePanelSupport: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelSupport.handleScroll(
      '.fadePanelSupport',
      'fadeIn 0.8s ease-in-out forwards',
      0.2,
      0
    );

    // Animation du panel Amabassadeur
    let fadePanelTestimony: ScrollAnimation = new ScrollAnimation(
      this.document
    );
    fadePanelTestimony.handleScroll(
      '.fadePanelTestimony',
      'fadeIn 0.8s ease-in-out forwards',
      0.2,
      0
    );
  }
}
