<div class="panel-card">
    <div class="panel-card-content">
        <mat-icon [style.color]="borderColor" fontIcon="{{matIconName}}"></mat-icon>
        <div *ngIf="title" class="fs-21 fw-600 primary-color">{{title}}</div>
        <div *ngIf="imgSeparator"><img src="{{imgSeparator}}" alt="separator"></div>
        <div class="fs-21 fw-550 primary-color">{{text}}</div>
    </div>
    <img src="{{imgSrc}}" alt="{{imgAlt}}"/>
</div>

