import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sub-menu-item',
  templateUrl: './sub-menu-item.component.html',
  styleUrls: ['./sub-menu-item.component.scss']
})
export class SubMenuItemComponent {

  @Input() title!: string;
  @Input() icon!: string;
  @Input() description!: string;
  @Input() route!: string;

  @Input() fontStyleTitle!: string;
  @Input() fontStyleTitleSelected!: string;
  @Input() fontStyleDesc!: string;

  @Input() isSelected: boolean = false;

  @Input() withArrow: boolean = true;

  @Output() eventClick: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    if (this.fontStyleTitle == null) {
      this.fontStyleTitle = "fw-700 fs-16 primary-color";
    }
    if (this.fontStyleTitleSelected == null) {
      this.fontStyleTitleSelected = "fw-700 fs-16 primary-color";
    }
    if (this.fontStyleDesc == null) {
      this.fontStyleDesc = "fw-400 fs-16 primary-color";
    }
  }

  onClick() {
    this.eventClick.emit();
  }
}
