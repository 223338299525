import {
  Component,
  EventEmitter,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/service/navigation/navigation.service';
import { TreeMenuItemComponent } from '../../tools/menu/tree-menu-item/tree-menu-item.component';

@Component({
  selector: 'app-sidenav-content',
  templateUrl: './sidenav-content.component.html',
  styleUrls: ['./sidenav-content.component.scss'],
})
export class SidenavContentComponent {
  @Output() onCloseMenu = new EventEmitter<any>();

  // Liste des menus item
  @ViewChildren(TreeMenuItemComponent)
  menuitems?: QueryList<TreeMenuItemComponent>;

  currentLanguage!: string;
  currentLanguageShort!: string;

  constructor(
    private navigationService: NavigationService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.translate.onLangChange.subscribe(() => {
      this.updateCurrentLanguage();
    });
  }

  closeMenu() {
    // Émettre l'événement avec l'élément cliqué
    this.closeAllMenu();
    this.onCloseMenu.emit();
  }

  closeAllMenu() {
    this.menuitems?.forEach((item) => {
      if (item.isOpen()) {
        item.toggleSubMenu();
      }
    });
  }

  closeAllOtherMenu(menuItem: TreeMenuItemComponent) {
    this.menuitems?.forEach((item) => {
      if (item !== menuItem && item.isOpen()) {
        item.toggleSubMenu();
      }
    });
  }

  changeLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('userLang', language);
    this.closeMenu();
  }

  private updateCurrentLanguage() {
    this.currentLanguage = this.translate.currentLang;
    const languageShort = this.currentLanguage.substring(
      this.currentLanguage.length - 2,
      this.currentLanguage.length
    );
    this.currentLanguageShort = languageShort == 'GB' ? 'EN' : languageShort;
  }

  redirectTo(path: string) {
    this.closeMenu();
    this.navigationService.navigateTo(path);
  }

  callOpenAxeptioCookies() {
    (window as any).openAxeptioCookies();
  }

  openLogin() {
    let url = 'https://app.moffi.io/';
    window.open(url, '_blank');
  }
}
