<div id={{id}} class="offer-item">

    <div>
        <div *ngIf="selected" class="offer-selected-button" [@fadeInOut]>
            <mat-icon fontIcon="rocket_launch"></mat-icon>
            <span>{{'pricing.recommended' | translate}}</span>
        </div>
    </div>
    

    <div [class]="'offer-item-' + type +  (selected ? ' offer-item-' + type + '-selected' : '')" 
        [ngClass]="{'offer-item-container': !(isHandset$ | async) && !selected, 
            'offer-item-container-mobile': (isHandset$ | async) && !selected,
            'offer-item-container-selected': !(isHandset$ | async) && selected, 
            'offer-item-container-mobile-selected': (isHandset$ | async) && selected}">

        <!-- Background -->
        <div *ngIf="type == 'dark'" class="offer-item-background">
            <!-- Gradient Footer Left-->
            <div style="width: 50rem; height: 50rem; top: 0%; left:17%;" class="gradient-circle"></div>
        </div>

        <div class="offer-item-content">
            <!-- Header -->
            <div class="offer-item-header">
                <div class="offer-item-title">{{title}}</div>
                <div class="offer-item-target">{{target}}</div>
            </div>

            <!-- Features -->
            <div class="offer-item-features">
                <div class="offer-item-feature-title">{{featuresTitle}}</div>
                <ul>
                    <li *ngFor="let feature of features">
                        <mat-icon fontIcon="done"></mat-icon>
                        <div>{{feature}}</div>
                    </li>
                </ul>
            </div>
        </div>
    

        <div class="offer-item-content">
            <!-- More -->
            <div class="offer-item-more">
                <div class="offer-item-more-title">{{moreTitle}}</div>
                <div class="offer-item-more-description">
                    <mat-icon fontIcon="arrow_forward"></mat-icon>
                    <span>{{moreDescription}}</span>
                </div>
            </div>

            <!-- Bouton -->
            <div>
                <moffi-ui-button (click)="onClick()" *ngIf="type != 'dark'" buttonType="light" [label]="buttonLabel"></moffi-ui-button>
                <moffi-ui-button (click)="onClick()" *ngIf="type == 'dark'" buttonType="dark" [label]="buttonLabel"></moffi-ui-button>
            </div>
        </div>
        
    </div>

</div>
