import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appColoredText]',
  standalone: true
})
export class ColoredTextDirective {

  @Input() textColor: string = '#B494FF'; // Couleur par défaut
  @Input() textKey: string = '';

  color: string = '#B494FF';
  translatedText: string = '';
  coloredHtml: string = '';

  constructor(private el: ElementRef, private renderer: Renderer2, private translate: TranslateService) { 
    this.translate.onLangChange.subscribe(lang => {
      this.translateText();
    });
  }

  ngOnInit() {
    this.translateText();
  }

  translateText() {
    if (this.textKey) {
      this.translate.get(this.textKey).subscribe((translated: string) => {
        this.applyColor(translated);
      });
    }
  }

  ngAfterViewChecked(): void {
    if (this.color != this.textColor) {
      this.color = this.textColor;
      this.updateColor(this.coloredHtml);
    }
  }

  private applyColor(newText: string | undefined): void {
    this.color = this.textColor;
    // Premier appel
    if (newText != undefined) {
      this.translatedText = newText;
      this.coloredHtml = this.colorWords(this.translatedText);
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.coloredHtml);
    } 
  }

  private updateColor(content: string) {
    const regex = /style="color:\s*([^"]+)"/;
    const [, currentColor = ''] = RegExp(regex).exec(content) ?? [];

    // Vous pouvez maintenant mettre à jour la couleur en fonction de votre logique
    const updatedContent = content.replace(`color: ${currentColor}`, `color: ${this.textColor}`);
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', updatedContent);
  }

  private colorWords(text: string): string {
    const regex = /\*(.*?)\*/g;
    return text.replace(regex, `<span class="colored-title" style="color: ${this.textColor}">$1</span>`);
  }



}
