import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ContentChildren, QueryList } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { OfferItemComponent } from './offer-item/offer-item.component';

@Component({
  selector: 'app-offers-panel',
  templateUrl: './offers-panel.component.html',
  styleUrl: './offers-panel.component.scss'
})
export class OffersPanelComponent {

  // Liste des offers item
  @ContentChildren(OfferItemComponent) offerItems!: QueryList<OfferItemComponent>;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 1150px)')
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor(private breakpointObserver: BreakpointObserver) {
  }

  setSelectedById(id: string) {
    this.offerItems.forEach(item => {
      if (item.id == id) {
        item.setSelectedItem(true);
        this.unselectOtherItems(id);
      }
    });
  }


  unselectOtherItems(id: string) {
    this.offerItems.forEach(item => {
      if (item.id != id) {
        item.setSelectedItem(false);
      }
    });
  }

}
