<div
  [ngClass]="
    !(isHandset$ | async) ? 'client-container' : 'client-container-mobile'
  "
>
  <h3>{{ title }}</h3>
  <div class="client-content">
    <img
      *ngFor="let client of clients"
      style="max-width: 40%"
      src="assets/images/landings/page/clients/{{ client }}.svg"
      alt="client {{ client }}"
    />
  </div>
</div>
