import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightAndMargin'
})
export class HighlightAndMarginPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;
    return value.replace(/<b>(.*?)<b>/g, '<span class="highlight-margin">$1</span>');
  }

}