<div class="page-content">
    <!----------------------- Header ------------------------->
    <div [style.margin-top]="!(isHandset$ | async) ? '5rem' : '3rem'" [ngClass]="!(isHandset$ | async) ? 'page-header' : 'page-header-mobile'" class="page-panel">
        <!-- Titre -->
        <div class="page-panel" style="margin-top: 0rem;">
            <div class="page-panel-title">
                <h1 class="fadePanelHeader fade-panel" appColoredText [textColor]="'#074DCE'" textKey="pricing.caption.title"></h1>
                <h4 class="fadePanelHeader fade-panel">{{'pricing.caption.description' | translate}}</h4>
            </div>
        </div>
    </div>

    <!----------------------- Formulaire ------------------------->
    <div style="margin-top: 5rem;" class="page-panel">
        <div [ngClass]="!(isHandset$ | async) ? 'form-offer-container' : 'form-offer-container-mobile'" class="fade-panel fadePanelHeader">
            <!-- Titre -->
            <h3 style="color: #043082;" class="fs-20 fw-400">{{'pricing.filters.intro' | translate}}</h3>
            <form [ngClass]="!(isHandset$ | async) ? 'form-offer' : 'form-offer-mobile'" [formGroup]="form" (ngSubmit)="choseOffer(formDirective)" #formDirective="ngForm">
                <div [ngClass]="!(isHandset$ | async) ? 'form-fields' : 'form-fields-mobile'">
                    <!-- Postes de travail-->
                    <moffi-ui-textbox [style.width]="(isHandset$ | async) ? '100%' : ''" 
                        id="desk" 
                        name="desk"
                        [formControl]="getFormControl('desk')"
                        type="number" 
                        [width]="!(isHandset$ | async) ? '13rem' : '100%'"
                        [customErrorMessages]="errorKeys"
                        [floatLabel]="false"
                        label="{{'pricing.filters.desk.label' | translate}}" 
                        placeholder="{{'pricing.filters.desk.placeholder' | translate}}" 
                        [required]="true"
                        [enabled]="true"
                        ngDefaultControl>
                    </moffi-ui-textbox>
                    <!-- Salles de réunion-->
                    <moffi-ui-textbox [style.width]="(isHandset$ | async) ? '100%' : ''"  
                        id="meetingRoom" 
                        name="meetingRoom"
                        [formControl]="getFormControl('meetingRoom')"
                        type="number" 
                        [width]="!(isHandset$ | async) ? '13rem' : '100%'"
                        [customErrorMessages]="errorKeys"
                        [floatLabel]="false"
                        label="{{'pricing.filters.meeting_room.label' | translate}}" 
                        placeholder="{{'pricing.filters.meeting_room.placeholder' | translate}}"
                        [required]="true"
                        [enabled]="true"
                        ngDefaultControl>
                    </moffi-ui-textbox>
                    <!-- Vous voulez aussi piloter -->
                    <moffi-ui-combobox [style.width]="(isHandset$ | async) ? '100%' : ''"  
                        id="features" 
                        name="features"
                        label="{{'pricing.filters.features.label' | translate}}"
                        placeholder="{{'pricing.filters.features.placeholder' | translate}}"
                        [width]="!(isHandset$ | async) ? '22rem' : '100%'"
                        [floatLabel]="false"
                        [formControl]="getFormControl('features')"
                        [customErrorMessages]="errorKeys"
                        [multipleEnabled]="true"
                        [options]="features"
                        ngDefaultControl>
                    </moffi-ui-combobox>
    
                    <!-- Pour la conduite du changement -->
                    <moffi-ui-combobox [style.width]="(isHandset$ | async) ? '100%' : ''"  
                        id="changeManagement" 
                        name="changeManagement"
                        label="{{'pricing.filters.change_management.label' | translate}}"
                        placeholder="{{'pricing.filters.change_management.placeholder' | translate}}"
                        [required]="true"
                        [floatLabel]="false"
                        [formControl]="getFormControl('changeManagement')"
                        [customErrorMessages]="errorKeys"
                        [width]="!(isHandset$ | async) ? '23rem' : '100%'"
                        [options]="changeManagement"
                        ngDefaultControl>
                    </moffi-ui-combobox>
                </div>
                <div class="fadePanel">
                    <moffi-ui-button buttonType="light"  type="submit" label="{{'pricing.filters.button' | translate}}" srcIcon="/assets/images/icon/icon_arrow_button.svg"></moffi-ui-button>
                </div>
            </form>
        </div>
    </div>
    
    <!----------------------- Les Offres ------------------------->
    <div style="margin-top: 5rem;" class="page-panel">
        <app-offers-panel>
            <!-- Essentiel -->
            <app-offer-item 
                style="max-width: 95%;"
                [id]="ESSENTIAL"
                type="light"
                title="{{'pricing.essential.title' | translate}}"
                target="{{'pricing.essential.target' | translate}}"
                featuresTitle="{{'pricing.essential.features.intro' | translate}}"
                [features]="[
                  'pricing.essential.features.text1' | translate,
                  'pricing.essential.features.text2' | translate,
                  'pricing.essential.features.text3' | translate,
                  'pricing.essential.features.text4' | translate,
                  'pricing.essential.features.text5' | translate
                ]"
                moreTitle="{{'pricing.essential.more.title' | translate}}"
                moreDescription="{{'pricing.essential.more.text' | translate}}"
                buttonLabel="{{'pricing.essential.button' | translate}}"
                (onButtonClick)="redirectTo('contact')"
                ></app-offer-item>
            <!-- Avancé -->
            <app-offer-item style="max-width: 95%;"
                [id]="ADVANCED"
                type="colored"
                title="{{'pricing.advanced.title' | translate}}"
                target="{{'pricing.advanced.target' | translate}}"
                featuresTitle="{{'pricing.advanced.features.intro' | translate}}"
                [features]="[
                  'pricing.advanced.features.text1' | translate,
                  'pricing.advanced.features.text2' | translate,
                  'pricing.advanced.features.text3' | translate,
                  'pricing.advanced.features.text4' | translate,
                  'pricing.advanced.features.text5' | translate
                ]"
                moreTitle="{{'pricing.essential.more.title' | translate}}"
                moreDescription="{{'pricing.advanced.more.text' | translate}}"
                buttonLabel="{{'pricing.essential.button' | translate}}"
                (onButtonClick)="redirectTo('contact')"
                ></app-offer-item>
            <!-- Premium -->
            <app-offer-item style="max-width: 95%;"
                [id]="PREMIUM"
                type="dark"
                title="{{'pricing.premium.title' | translate}}"
                target="{{'pricing.premium.target' | translate}}"
                featuresTitle="{{'pricing.premium.features.intro' | translate}}"
                [features]="[
                  'pricing.premium.features.text1' | translate,
                  'pricing.premium.features.text2' | translate,
                  'pricing.premium.features.text3' | translate,
                  'pricing.premium.features.text4' | translate,
                  'pricing.premium.features.text5' | translate
                ]"
                moreTitle="{{'pricing.essential.more.title' | translate}}"
                moreDescription="{{'pricing.premium.more.text' | translate}}"
                buttonLabel="{{'pricing.essential.button' | translate}}"
                (onButtonClick)="redirectTo('contact')"
                ></app-offer-item>
        </app-offers-panel>
    </div>

    <!----------------------- Nos solutions smart office ------------------------->
    <div class="page-panel" >
        <!-- Version desktop -->
        <div *ngIf="!(isHandset$ | async)" class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fadePanelSolutions fade-panel page-horizontal-panel-item">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="pricing.technology.title"></h2>
                <h3 class="page-horizontal-panel-item-text first-color">{{'pricing.technology.description' | translate}}</h3>
                <!-- Bouton En savoir plus -->
                <div style="margin-top: 2rem;">
                    <moffi-ui-button (eventClick)="redirectTo('smart')" buttonType="light"  label="{{'pricing.technology.button' | translate}}"></moffi-ui-button>
                </div>
            </div>

            <!-- Image -->
            <div class="fadePanelSolutions fade-panel page-horizontal-panel-img" style = "margin-left: 3rem;">
                <img src="assets/images/landings/offer/offer_tree.webp" alt="{{'pricing.technology.alt' | translate}}"/>
            </div> 
        </div>
        <!-- Version mobile -->
        <div *ngIf="(isHandset$ | async)" class="page-horizontal-panel">
            <!-- Texte -->
            <div class="fadePanelSolutions fade-panel page-panel">
                <h2 appColoredText [textColor]="'#074DCE'" textKey="pricing.technology.title"></h2>
                <h3 class="first-color">{{'pricing.technology.description' | translate}}</h3>
            </div>
            <!-- Image -->
            <div class="fadePanelSolutions fade-panel page-horizontal-panel-img">
                <img src="assets/images/landings/offer/offer_tree.webp" alt="{{'pricing.technology.alt' | translate}}"/>
            </div>
            <!-- Bouton En savoir plus-->
            <div class="fadePanelSolutions fade-panel" style="margin-top: 2rem;">
                <moffi-ui-button (eventClick)="redirectTo('smart')" buttonType="light"  label="{{'pricing.technology.button' | translate}}"></moffi-ui-button>
            </div>
        </div>
    </div>


    <!----------------------- FAQ ------------------------->
    <div [ngClass]="!(isHandset$ | async) ? 'page-panel-left' : 'page-panel'">
        <!-- Title -->
        <div class="fadePanelFaq fade-panel page-horizontal-panel-item">
            <h2 appColoredText [textColor]="'#074DCE'" textKey="pricing.faq.title"></h2>
            <h3 class="page-horizontal-panel-item-text third-color">{{'pricing.faq.description' | translate}}</h3>
        </div>
       
        <!-- FAQ -->
        <mat-accordion class="faq-panel fadePanelFaq fade-panel" multi>
            <!-- Durée de déploiement -->
            <mat-expansion-panel #panelSetup hideToggle="true">
              <mat-expansion-panel-header class="faq-header">
                <mat-panel-title class="faq-panel-title">{{'pricing.faq.setup.title' | translate}}</mat-panel-title>
                <mat-panel-description>
                    <mat-icon *ngIf="!panelSetup.expanded" fontIcon="add"></mat-icon>
                    <mat-icon *ngIf="panelSetup.expanded" fontIcon="remove"></mat-icon>
                  </mat-panel-description> 
              </mat-expansion-panel-header>
              <div class="faq-panel-content">{{'pricing.faq.setup.description' | translate}}</div>
              
            </mat-expansion-panel>
            <!-- Tarif -->
            <mat-expansion-panel #panelPricing hideToggle="true">
                <mat-expansion-panel-header class="faq-header">
                  <mat-panel-title class="faq-panel-title">{{'pricing.faq.pricing.title' | translate}}</mat-panel-title>
                  <mat-panel-description>
                    <mat-icon *ngIf="!panelPricing.expanded" fontIcon="add"></mat-icon>
                    <mat-icon *ngIf="panelPricing.expanded" fontIcon="remove"></mat-icon>
                  </mat-panel-description> 
                </mat-expansion-panel-header>
                <div class="faq-panel-content">{{'pricing.faq.pricing.description' | translate}}</div>
              </mat-expansion-panel>
              <!-- Sécurité -->
            <mat-expansion-panel #panelData hideToggle="true">
                <mat-expansion-panel-header class="faq-header">
                  <mat-panel-title class="faq-panel-title">{{'pricing.faq.data.title' | translate}}</mat-panel-title>
                  <mat-panel-description>
                    <mat-icon *ngIf="!panelData.expanded" fontIcon="add"></mat-icon>
                    <mat-icon *ngIf="panelData.expanded" fontIcon="remove"></mat-icon>
                  </mat-panel-description> 
                </mat-expansion-panel-header>
                <div class="faq-panel-content">{{'pricing.faq.data.description' | translate}}</div>
              </mat-expansion-panel>
              <!-- Engagement -->
            <mat-expansion-panel #panelCommitment hideToggle="true">
                <mat-expansion-panel-header class="faq-header">
                  <mat-panel-title class="faq-panel-title">{{'pricing.faq.commitment.title' | translate}}</mat-panel-title>
                  <mat-panel-description>
                    <mat-icon *ngIf="!panelCommitment.expanded" fontIcon="add"></mat-icon>
                    <mat-icon *ngIf="panelCommitment.expanded" fontIcon="remove"></mat-icon>
                  </mat-panel-description> 
                </mat-expansion-panel-header>
                <div class="faq-panel-content">{{'pricing.faq.commitment.description' | translate}}</div>
              </mat-expansion-panel>
        </mat-accordion>
    </div>


    <!----------------------- Bandeau démo ------------------------->
    <div style="margin-top: 5rem;" class="page-demo-panel" >

        <!-- Background -->
        <div class="page-demo-background">
            
            <div style="width: 50rem; height: 40rem; top: -61%; left:76%;" class="gradient-circle blue-gradient"></div>
            <div style="top: -8%; left:80%;transform: rotate(140deg);opacity:0.9;" class="gradient-small-circle pink-gradient"></div>
            <div style="top: -13%; left:82%;transform: rotate(140deg); filter: blur(5rem);" class="gradient-small-circle yellow-gradient"></div>
            <div style="width: 50rem; height: 40rem; top: 40%; left:65%;" class="gradient-circle blue-gradient"></div>
            <div style="top: 39%; left:81%;opacity: 0.8;" class="gradient-small-circle green-gradient"></div>
            <div style="width: 50rem; height: 50rem; top: 76%; left:73%; opacity:0.7;" class="gradient-circle purple-gradient"></div> 
            
        </div>


        <!-- title -->
        <div class="page-panel-title">
            <img src="assets/images/landings/page/hands/hand_demo.svg" alt="icon_hand_demo"/>
            <h2 class="dark-color">{{'pricing.cta.title' | translate}}</h2>
            <h4 class="dark-color" appColoredText [textColor]="'#A1ABE2'" textKey="pricing.cta.description"></h4>
        </div>

        <!-- Bouton démo -->
        <moffi-ui-button (eventClick)="redirectTo('contact')" buttonType="dark" label="{{'pricing.cta.button' | translate}}"></moffi-ui-button>
    </div>

</div>
