import { Component } from '@angular/core';
import { AbstractPageComponent } from '../abstract/page/abstract.page.component';
import { ScrollAnimation } from 'src/app/utils/scroll-animations';
import { Subscription, interval } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-flex',
  templateUrl: './flex.component.html',
  styleUrl: './flex.component.scss',
})
export class FlexComponent extends AbstractPageComponent {
  assetsTableData: any[] = [
    {
      assetKey: 'flex.assets.optimisation',
      withoutMoffi: false,
      withMoffi: true,
    },
    {
      assetKey: 'flex.assets.visibility',
      withoutMoffi: false,
      withMoffi: true,
    },
    {
      assetKey: 'flex.assets.teams',
      withoutMoffi: false,
      withMoffi: true,
    },
    {
      assetKey: 'flex.assets.google',
      withoutMoffi: false,
      withMoffi: true,
    },
    { assetKey: 'flex.assets.costs', withoutMoffi: false, withMoffi: true },
    { assetKey: 'flex.assets.hr', withoutMoffi: false, withMoffi: true },
    {
      assetKey: 'flex.assets.sustainability',
      withoutMoffi: false,
      withMoffi: true,
    },
    { assetKey: 'flex.assets.stats', withoutMoffi: false, withMoffi: true },
  ];

  assetsTableDataMobile: any[] = [
    { assetKey: 'flex.assets.optimisation' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'flex.assets.visibility' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    {
      assetKey: 'flex.assets.teams',
    },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    {
      assetKey: 'flex.assets.google',
    },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'flex.assets.costs' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'flex.assets.hr' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'flex.assets.sustainability' },
    { assetKey: '', withoutMoffi: false, withMoffi: true },
    { assetKey: 'flex.assets.stats' },
    { assetKey: '', withoutMoffi: false, withMoffi: true, isLastElement: true },
  ];

  listColorMobile: string[] = ['purple', 'pink', 'yellow', 'green'];

  currentColor: string = 'purple';
  currentIndex: number = 0;
  subscriptionColorChange?: Subscription;

  override ngOnInit(): void {
    super.ngOnInit();

    if (isPlatformBrowser(this.platformId)) {
      this.subscriptionColorChange = interval(2000).subscribe(() => {
        this.changeCurrentColor();
      });
    }
  }

  changeCurrentColor() {
    this.currentIndex = (this.currentIndex + 1) % this.listColorMobile.length;
    this.currentColor = this.listColorMobile[this.currentIndex];
  }

  override setHeaderMetadatas(): void {
    this.title.setTitle(this.getTranslation('flex.metadata.title'));
    this.meta.updateTag({
      name: 'description',
      content: this.getTranslation('flex.metadata.description'),
    });
  }

  override launchAnimations(): void {
    // Animations du header
    let fadePanelHeader: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelHeader.handleScroll(
      '.fadePanelHeader',
      'fadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );

    // Animation des images du header
    let popImgHeader: ScrollAnimation = new ScrollAnimation(this.document);
    popImgHeader.handleScroll(
      '.headerImage',
      'scaleIn 2s ease-in-out forwards',
      0.5,
      0
    );
    let popImgHeader2: ScrollAnimation = new ScrollAnimation(this.document);
    popImgHeader2.handleScroll(
      '.headerImagePic',
      'scaleOut 2s ease-in-out forwards',
      0.7,
      0
    );

    // Animation du panel Client
    let fadePanelClient: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelClient.handleScroll(
      '.fadePanelClient',
      'fadeIn 0.5s ease-in-out forwards',
      0.15,
      0
    );

    // Animation du panel Partenaire flex office
    this.createAnimationHorizontalPanel('.fadePanelApp');
    // Animation du panel Custom
    this.createAnimationHorizontalPanel('.fadePanelCustom');
    // Animation du panel Custom
    this.createAnimationHorizontalPanel('.fadePanelData');

    // Animation du panel Assets
    let fadePanelAssets: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelAssets.handleScroll(
      '.fadePanelAssets',
      'fadeIn 0.8s ease-in-out forwards',
      0.2,
      0
    );

    // Animation du panel Hybrid
    this.createAnimationHorizontalPanel('.fadePanelHybrid');

    // Animation du panel Accompagnement
    let fadePanelSupport: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelSupport.handleScroll(
      '.fadePanelSupport',
      'fadeIn 0.8s ease-in-out forwards',
      0.2,
      0
    );

    // Animation du panel Amabassadeur
    let fadePanelTestimony: ScrollAnimation = new ScrollAnimation(
      this.document
    );
    fadePanelTestimony.handleScroll(
      '.fadePanelTestimony',
      'fadeIn 0.8s ease-in-out forwards',
      0.2,
      0
    );
  }
}
