import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-list-client',
  templateUrl: './list-client.component.html',
  styleUrl: './list-client.component.scss'
})
export class ListClientComponent {

  @Input() title?: string;
  @Input() clients?: string[];

  // Retourne true si l'écran est en mode mobile
  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 1150px)')
    .pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor (protected breakpointObserver: BreakpointObserver) {

  }

}
