import { Component } from '@angular/core';
import { AbstractPageComponent } from '../abstract/page/abstract.page.component';
import { ScrollAnimation } from 'src/app/utils/scroll-animations';
import { EPartnerTag, ModelListPartners, ModelPartner } from 'src/app/model/model-partner';
import { environment } from 'src/environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrl: './marketplace.component.scss',
  animations: [
    trigger('fadeSlideInOut', [
      state('in', style({ opacity: 1, height: '*', visibility: 'visible', marginTop: '1rem'})),
      state('out', style({ opacity: 0, height: '0px', visibility: 'hidden', marginTop: '0rem' })),
      transition('in => out', [
        animate('300ms ease-in-out', style({ opacity: 0, height: '0px', marginTop: '0rem' })),
        animate('300ms ease-in-out', style({ visibility: 'hidden' }))
      ]),
      transition('out => in', [
        style({ visibility: 'visible', marginTop: '0rem' }), 
        animate('300ms ease-in-out', style({ opacity: 1, height: '*', marginTop: '1rem' }))
      ])
    ])
  ]
})
export class MarketplaceComponent extends AbstractPageComponent {


  listPartners!: ModelListPartners;
  defaultMostPopular: ModelPartner[] = [];
  filteredPartners: ModelPartner[] = [];

  partnerTags = EPartnerTag;
  selectedTags: { [key in EPartnerTag]?: boolean } = {};
  allComplete: boolean = false;

  isFiltersVisible: boolean = false;


  override ngOnInit() {
    super.ngOnInit();
    this.partnersService.getListPartners().subscribe(result => {
      this.listPartners = result;

      // Default Most Popular
      this.listPartners.defaultMostPopular.forEach(key => {
        this.listPartners?.partners.forEach(partner => {
          if (key == partner.key) {
            this.defaultMostPopular?.push(partner);
          }

          //Alimentation de la liste des tags
          partner.tagsEnum = partner.tags.map(tag => EPartnerTag[tag as keyof typeof EPartnerTag]);

        });
      });
      this.filterPartners();
    });

    // Observer les changements dans isChildRoute$
    this.isChildRoute$.subscribe((value: boolean) => {
      if (!value) {
        this.launchAnimations();
        this.selectedTags = {};
        this.filterPartners();
      }
    });
  }

  filterPartners(partnerName: string = ""): void {
    const activeTags: EPartnerTag[] = Object.entries(this.selectedTags)
            .filter(([key, value]) => value) // Garder seulement les entrées où la valeur est true
            .map(([key]) => key as EPartnerTag);

    this.filteredPartners = this.listPartners?.partners.filter(partner => {
      // Filtrage par nom
      const nameMatches = partner.name.toLowerCase().includes(partnerName.toLowerCase());
      // Filtrage par tagsEnum
      const tagsMatch = partner.tagsEnum.includes(EPartnerTag.ALL_CATEGORIES) || 
                      activeTags.length === 0 || 
                      partner.tagsEnum.some(tag => activeTags.includes(tag));
      return nameMatches && tagsMatch;
  });
  }

  openPartnerDetail(key: string) {
    this.redirectTo("marketplace", key);
  }


  onCheckboxChange(tag: EPartnerTag, isChecked: boolean) {
    this.selectedTags[tag] = isChecked;
    this.allComplete = this.areAllCheckboxesChecked();
    this.filterPartners();
  }

  getEnumKeys(): EPartnerTag[] {
    return Object.keys(this.partnerTags)
      .filter(key => this.partnerTags[key as keyof typeof EPartnerTag] !== EPartnerTag.ALL_CATEGORIES)
      .map(key => this.partnerTags[key as keyof typeof EPartnerTag] as EPartnerTag);
  }

  someComplete(): boolean {
    return Object.values(this.selectedTags).some(value => value === true) && !this.allComplete;
  }

  areAllCheckboxesChecked(): boolean {
    return this.getEnumKeys().every(tag => this.selectedTags[tag]);
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    this.getEnumKeys().forEach(tag => {
      this.selectedTags[tag] = completed;
    });
    this.filterPartners();
  }

  getModelPartnerByKey(key : string): ModelPartner | null {
    let result = null;
    this.listPartners?.partners.forEach(partner => {
      if (key == partner.key) {
        result = partner;
      }
    });
    return result;
  }

  toggleFilters() {
    this.isFiltersVisible = !this.isFiltersVisible;
  }

  override setHeaderMetadatas(): void {
    this.title.setTitle(this.getTranslation('marketplace.metadata.title'));
    this.meta.updateTag({name: 'description', content: this.getTranslation('marketplace.metadata.description'),});
  
    document
      ?.getElementById('alternate-fr-fr')
      ?.setAttribute('href', environment.frontUrl + '/fr/contact');
  }


  override launchAnimations(): void {
    // Animations du header
    let fadePanelHeader: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelHeader.handleScroll('.fadePanelHeader', 'fadeIn 0.8s ease-in-out forwards',0.3,0);

    // Animations du panel de recherche
    let fadePanelPartnerSearch: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelPartnerSearch.handleScroll('.fadePanelPartnerSearch', 'fadeIn 0.8s ease-in-out forwards',0.3,0);
  }



}
