import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NavigationService } from '../navigation/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private frFr: any;
  private frCa: any;
  private enGb: any;

  private isInit: boolean = false;
  private initChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private translate: TranslateService,
    private navigationService: NavigationService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.init();
  }

  async init() {
    this.frFr = await this.translate.getTranslation('fr-FR').toPromise();
    this.frCa = await this.translate.getTranslation('fr-CA').toPromise();
    this.enGb = await this.translate.getTranslation('en-GB').toPromise();

    this.isInit = true;
    this.initChange.emit(true);
  }

  async updateLangAttribute() {
    this.document
      .querySelector('html')
      ?.setAttribute('lang', this.translate.currentLang);
  }

  async updateCanonicalLink() {
    if (!this.isInit) {
      await firstValueFrom(this.initChange);
    }

    let currentPage = this.navigationService.currentPage ?? 'home';
    const path = currentPage + '.metadata.path';

    let url = this.translate.instant(path);
    if (url === path) {
      url = null;
    }

    const canonical =
      environment.frontUrl +
      '/' +
      this.navigationService.getLangShortText(this.translate.currentLang) +
      (url ? '/' + url : '');

    this.document.querySelector('#canonical')?.setAttribute('href', canonical);
  }

  async updateAlternateLinks() {
    if (!this.isInit) {
      await firstValueFrom(this.initChange);
    }

    let currentPage = this.navigationService.currentPage ?? 'home';
    const path = (currentPage + '.metadata.path').split('.');

    const urlFrFr = this.getNestedObject(this.frFr, path);
    const urlFrCa = this.getNestedObject(this.frCa, path);
    const urlEnGb = this.getNestedObject(this.enGb, path);

    const urls = {
      'fr-FR': '/fr' + (urlFrFr ? '/' + urlFrFr : ''),
      'fr-CA': '/fr-ca' + (urlFrCa ? '/' + urlFrCa : ''),
      'en-GB': '/en' + (urlEnGb ? '/' + urlEnGb : ''),
    };

    this.document
      .querySelector('#alternate-fr-fr')
      ?.setAttribute('href', environment.frontUrl + urls['fr-FR']);
    this.document
      .querySelector('#alternate-fr-ca')
      ?.setAttribute('href', environment.frontUrl + urls['fr-CA']);
    this.document
      .querySelector('#alternate-en-gb')
      ?.setAttribute('href', environment.frontUrl + urls['en-GB']);

    if (currentPage === 'home') {
      const alternateXDefault = this.document.createElement('link');
      alternateXDefault.rel = 'alternate';
      alternateXDefault.hreflang = 'x-default';
      alternateXDefault.href = environment.frontUrl;
      alternateXDefault.id = 'alternate-x-default';

      this.document.querySelector('head')?.appendChild(alternateXDefault);
    } else {
      this.document.querySelector('#alternate-x-default')?.remove();
    }
  }

  private getNestedObject = (nestedObj: any, pathArr: Array<string>) => {
    return pathArr.reduce(
      (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined),
      nestedObj
    );
  };
}
