export interface ModelOffer {
    desk: number;
    meetingRoom: string;
    features: EFeatures[];
    changeManagement: EChangeManagement;
}

export enum EFeatures {
    Remote = 'REMOTE',
    Absence = 'ABSENCE',
    Parking = 'PARKING'
}
  
export enum EChangeManagement {
    Yes = 'YES',
    No  = 'NO',
    Other = "OTHER"
}