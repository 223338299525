import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;
    return value.replace(/<b>(.*?)<b>/g, '<span class="highlight">$1</span>');
  }

}