import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin, map, switchMap } from 'rxjs';
import { ModelListDefinitions, ModelDefinition, ModelArticle, ModelListArticles} from "src/app/model/model-definition";


@Injectable({
  providedIn: 'root'
})
export class DefinitionsService {

  private definitionsUrl = 'assets/data/definitions.json';
  private articlesUrl = 'assets/data/articles.json';

  constructor(private http: HttpClient, private translateService : TranslateService) { }

  getListDefinitions(): Observable<ModelListDefinitions> {
    return this.http.get<ModelListDefinitions>(this.definitionsUrl)
  }

  getDefinitionByUrl(url: string): Observable<ModelDefinition> {
    return this.http.get<ModelListDefinitions>(this.definitionsUrl)
    .pipe(
      map(response => {
        const definitions = response.definitions;
        if (!definitions) {
          throw new Error('Definitions data is malformed or missing');
        }
        const definition = definitions.find(definition => definition.urls.includes(url));
        if (!definition) {
          throw new Error(`No definition found with url ${url}`);
        }

        // Autres définitions
        let otherDefinitions: ModelDefinition[] = [];
        definition.template.otherDefinitions.forEach(otherKey => {
          const other = definitions.find(definition => definition.key === otherKey);
          if (other) {
            otherDefinitions.push(other);
          }
        });
        definition.template.otherDefinitionsModel = otherDefinitions;

        return definition;
      }),
      switchMap(definition => 
        forkJoin(
          definition.template.articlesKey.map(articleKey => 
            this.getArticleByKey(articleKey)
          )
        ).pipe(
          map(articles => {
            definition.template.articles = articles;
            return definition;
          })
        )
      )
    );
  }


  getArticleByKey(key: string): Observable<ModelArticle> {
    return this.http.get<ModelListArticles>(this.articlesUrl)
    .pipe(
      map(response => {
        const articles = response.articles;
        if (!articles) {
          throw new Error('Articles data is malformed or missing');
        }
        const article = articles.find(article => article.key === key);
        if (!article) {
          throw new Error(`No article found with key ${key}`);
        }
        return article;
      })
    );
  }
}
