import { Component, ViewChild } from '@angular/core';
import { AbstractPageComponent } from '../abstract/page/abstract.page.component';
import { ScrollAnimation } from 'src/app/utils/scroll-animations';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { EChangeManagement, EFeatures } from 'src/app/model/model-form-offer';
import { OffersPanelComponent } from 'src/app/component/landings/offers-panel/offers-panel.component';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrl: './offer.component.scss',
})
export class OfferComponent extends AbstractPageComponent {
  ESSENTIAL: string = 'ESSENTIAL';
  ADVANCED: string = 'ADVANCED';
  PREMIUM: string = 'PREMIUM';

  // Offer Component
  @ViewChild(OffersPanelComponent) offerPanel!: OffersPanelComponent;

  // ListBox store
  changeManagement: any[] = [];
  features: any[] = [];

  // Controllers
  errorKeys = {};

  //Form Data
  form: FormGroup = new FormGroup({
    desk: new FormControl('', Validators.required),
    meetingRoom: new FormControl('', Validators.required),
    features: new FormControl([]),
    changeManagement: new FormControl('', Validators.required),
  });

  panelOpenState: boolean = false;

  override ngOnInit() {
    super.ngOnInit();

    // OnLangChange
    this.translateService.onLangChange.subscribe(() => {
      // Combobox Features
      this.features = [
        {
          key: EFeatures.Remote,
          value: this.getTranslation('pricing.filters.features.remote'),
        },
        {
          key: EFeatures.Absence,
          value: this.getTranslation('pricing.filters.features.absence'),
        },
        {
          key: EFeatures.Parking,
          value: this.getTranslation('pricing.filters.features.parking'),
        },
      ];
      // ComboBox Conduite du projet
      this.changeManagement = [
        {
          key: EChangeManagement.Yes,
          value: this.getTranslation('pricing.filters.change_management.yes'),
        },
        {
          key: EChangeManagement.No,
          value: this.getTranslation('pricing.filters.change_management.no'),
        },
        {
          key: EChangeManagement.Other,
          value: this.getTranslation(
            'pricing.filters.change_management.unknown'
          ),
        },
      ];
      // Erreurs
      this.errorKeys = {
        email: this.getTranslation('contact.form.name.error.format'),
        pattern: this.getTranslation('contact.form.name.error.format'),
        required: this.getTranslation('contact.form.name.error.mandatory'),
      };
    });

    Promise.all([
      this.getTranslation('pricing.filters.features.remote'),
      this.getTranslation('pricing.filters.features.absence'),
      this.getTranslation('pricing.filters.features.parking'),
      this.getTranslation('pricing.filters.change_management.yes'),
      this.getTranslation('pricing.filters.change_management.no'),
      this.getTranslation('pricing.filters.change_management.unknown'),
      this.getTranslation('contact.form.name.error.format'),
      this.getTranslation('contact.form.name.error.format'),
      this.getTranslation('contact.form.name.error.mandatory'),
    ])
      .then((translations) => {
        this.features = [
          { key: EFeatures.Remote, value: translations[0] },
          { key: EFeatures.Absence, value: translations[1] },
          { key: EFeatures.Parking, value: translations[2] },
        ];
        this.changeManagement = [
          { key: EChangeManagement.Yes, value: translations[3] },
          { key: EChangeManagement.No, value: translations[4] },
          { key: EChangeManagement.Other, value: translations[5] },
        ];
        this.errorKeys = {
          email: translations[6],
          pattern: translations[7],
          required: translations[8],
        };
      })
      .catch((error) => {
        console.error('Error fetching translations:', error);
      });
  }

  getFormControl(key: string): FormControl {
    return this.form.get(key) as FormControl;
  }

  choseOffer(formDirective: FormGroupDirective) {
    if (!this.form.valid) {
      return;
    }

    let offerSelected: string = '';

    const { changeManagement, meetingRoom, desk, features } = this.form.value;
    const totalRooms = this.calculateTotal(meetingRoom, desk);
    const featuresSize = features.length;

    switch (changeManagement) {
      case EChangeManagement.Yes:
        offerSelected = this.PREMIUM;
        break;
      case EChangeManagement.No:
        offerSelected = this.setOfferBasedOnCriteria(totalRooms, featuresSize);
        break;
      case EChangeManagement.Other:
        offerSelected = this.setOfferForOther(totalRooms, featuresSize);
        break;
    }

    if (offerSelected) {
      this.offerPanel.setSelectedById(offerSelected);
      this.scrollToOffer(offerSelected);
    }
  }

  calculateTotal(meetingRoom: string, desk: string): number {
    const nbMeetingRoom = parseInt(meetingRoom, 10) || 0;
    const nbDesk = parseInt(desk, 10) || 0;
    return nbMeetingRoom + nbDesk;
  }

  setOfferBasedOnCriteria(totalRooms: number, featuresSize: number): string {
    if (totalRooms > 100) {
      return this.ADVANCED;
    } else {
      return featuresSize < 2 ? this.ESSENTIAL : this.ADVANCED;
    }
  }

  setOfferForOther(totalRooms: number, featuresSize: number): string {
    if (totalRooms > 500) {
      return this.PREMIUM;
    } else if (totalRooms > 100) {
      return this.ADVANCED;
    } else {
      return featuresSize < 2 ? this.ESSENTIAL : this.ADVANCED;
    }
  }

  scrollToOffer(name: string) {
    const offerElement = document.getElementById(name);
    if (offerElement) {
      offerElement.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }

  override setHeaderMetadatas(): void {
    this.title.setTitle(this.getTranslation('pricing.metadata.title'));
    this.meta.updateTag({
      name: 'description',
      content: this.getTranslation('pricing.metadata.description'),
    });
  }

  override launchAnimations(): void {
    // Animations du header
    let fadePanelHeader: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelHeader.handleScroll(
      '.fadePanelHeader',
      'fadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );

    // Animations des solutions
    let fadePanelSolutions: ScrollAnimation = new ScrollAnimation(
      this.document
    );
    fadePanelSolutions.handleScroll(
      '.fadePanelSolutions',
      'fadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );

    // Animations des faq
    let fadePanelFaq: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelFaq.handleScroll(
      '.fadePanelFaq',
      'fadeIn 0.8s ease-in-out forwards',
      0.3,
      0
    );
  }
}
