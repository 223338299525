export interface ModelMailDemo {
    name: string;
    mail: string;
    phone: string;
    employees: EEmployeeSize;
    interests: EInterest[];
    captcha: string;
    message: string;
    partner: string | undefined;

}

export enum EEmployeeSize {
    OneToTwoHundred = 'ONE_TO_TWO_HUNDRED',
    TwoHundredAndOnToFiveHundred = 'TWO_HUNDRED_AND_ONE_TO_FIVE_HUNDRED',
    FiveHundredAndOnToOneThousand = 'FIVE_HUNDRED_AND_ONE_TO_ONE_THOUSAND',
    OneThousandAndOneToFiveThousand = 'ONE_THOUSAND_AND_ONE_TO_FIVE_THOUSAND',
    FiveThousandAndOneAndMore = 'FIVE_THOUSAND_AND_ONE_AND_MORE'
}
  
export enum EInterest {
    Desktop = 'DESKTOP',
    Meeting_room = 'MEETING_ROOM',
    Home_office = 'HOME_OFFICE',
    Parking = 'PARKING',
    Other = 'OTHER'
}