<div *ngIf="!(isChildRoute$ | async)" class="page-content">
    <!----------------------- Header ------------------------->
    <div [style.margin-top]="!(isHandset$ | async) ? '5rem' : '3rem'" [ngClass]="!(isHandset$ | async) ? 'page-header' : 'page-header-mobile'" class="page-panel">
        <!-- Titre -->
        <div class="page-panel" style="margin-top: 0rem;">
            <div class="page-panel-title">
                <h1 class="fadePanelHeader fade-panel" appColoredText [textColor]="'#074DCE'" textKey="marketplace.caption.title"></h1>
                <h4 class="fadePanelHeader fade-panel">{{'marketplace.caption.description' | translate}}</h4>
            </div>
        </div>
    </div>


    <!----------------------- Most Popular ------------------------->
    <div class="page-panel" style="margin-top: 6rem;">
        <div class="vertical-panel">
            <!-- Titre -->
            <h2 class="fadePanelHeader fade-panel" style="color: #03225C;">{{'marketplace.popular.title' | translate}}</h2>
            <!-- Liste des Partenaires les plus populaires -->
            <div [ngClass]="!(isHandset$ | async) ? 'partners-most-popular' : 'partners-most-popular-mobile'">
                <div class="fadePanelHeader fade-panel" *ngFor="let partner of defaultMostPopular">
                    <app-marketplace-item (onClickEvent)="openPartnerDetail($event)" [modelPartner]="partner" [width]="!(isHandset$ | async) ? '22rem' : '18rem'" height="12rem"></app-marketplace-item>
                </div>
            </div>
        </div>
    </div>
    

    <!----------------------- List Partners ------------------------->
    <div *ngIf="!(isHandset$ | async)" class="page-panel" style="margin-bottom: 5rem;overflow: hidden;">
        <div class="vertical-panel">
            <!-- Titre -->
            <h2 class="fadePanelPartnerSearch fade-panel" style="color: #03225C;">{{'marketplace.discover.title' | translate}}</h2>

            <!-- Liste des Partenaires -->
            <div class="partners-search-container">
                <!-- Filtres -->
                <div class="partners-search-filters fadePanelPartnerSearch fade-panel">
                    <!-- Titre -->
                    <h3 style="margin-bottom: 1rem;">{{ 'marketplace.category.title' | translate}}</h3>
                    <!-- Checkbox Tous -->
                    <moffi-ui-checkbox 
                        id="selectAll"
                        name="selectAll"
                        [checked]="allComplete" [indeterminate]="someComplete()" 
                        (onValueChangeEvent)="setAll($event.checked)"
                        label="{{'marketplace.category.all' | translate}}">
                    </moffi-ui-checkbox>
                    <!-- Liste des catégories -->
                    <div *ngFor="let tag of getEnumKeys()">
                        <moffi-ui-checkbox 
                            [id]="tag"
                            [name]="tag"
                            [checked]="selectedTags[tag]" 
                            (onValueChangeEvent)="onCheckboxChange(tag, $event.checked)"
                            label="{{ tag | translate}}">
                        </moffi-ui-checkbox>
                    </div>
                </div>
                <!-- Panel de recherche -->
                <div class="partners-search fadePanelPartnerSearch fade-panel">
                    <!-- Champ de recherche -->
                    <div style="width: 100%;">
                        <moffi-ui-textbox style="width: 100%;" 
                        id="name" 
                        name="name"
                        type="text" 
                        width="100%"
                        matIcon="search"
                        label="{{'marketplace.discover.searchbar.placeholder' | translate}}" 
                        [enabled]="true"
                        (onValueChangeEvent)="filterPartners($event)"
                        ngDefaultControl>
                    </moffi-ui-textbox>
                    </div>
                    <!-- Résultats -->
                    <div *ngIf="filteredPartners.length != 0" class="partners-results">
                        <div *ngFor="let partner of filteredPartners">
                            <app-marketplace-item 
                                (onClickEvent)="openPartnerDetail($event)" 
                                [modelPartner]="partner" 
                                width="16rem" 
                                height="12rem">
                            </app-marketplace-item>
                        </div>
                    </div>
                    <!-- Aucun résultat -->
                    <div *ngIf="filteredPartners.length == 0" class="partners-no-result">
                        <!-- Image loupe-->
                        <img src="assets/images/icon/icon_search.webp" alt="icon_search">
                        <!-- Text -->
                        <h3 class="fw-700">{{'marketplace.discover.empty.title' | translate}}</h3>
                        <h4 class="fs-16">{{'marketplace.discover.empty.text' | translate}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Version Mobile -->
    <div *ngIf="(isHandset$ | async)" class="page-panel" style="margin-bottom: 5rem;overflow: hidden;">
        <div class="vertical-panel fadePanelPartnerSearch fade-panel">
            <div style="gap:1rem;" class="partners-search">
                <!-- Champ de recherche -->
                <div style="width: 100%;">
                    <moffi-ui-textbox style="width: 100%;" 
                    id="name" 
                    name="name"
                    type="text" 
                    width="100%"
                    matIcon="search"
                    label="{{'marketplace.discover.searchbar.placeholder' | translate}}" 
                    [enabled]="true"
                    (onValueChangeEvent)="filterPartners($event)"
                    ngDefaultControl>
                </moffi-ui-textbox>
                </div>
                <!-- Filtres -->
                <div class="partners-search-filters-mobile">
                    <div style="padding: 1rem;width: 90%;">
                        <!-- Titre -->
                        <div class="partners-search-filters-mobile-title" (click)="toggleFilters()">
                            <h3>{{ 'marketplace.category.title' | translate}}</h3>
                            <mat-icon [ngClass]="isFiltersVisible ? 'filter-item-arrow-selected' : 'filter-item-arrow'" fontIcon="expand_more"></mat-icon>
                        </div>
                        
                        <div [@fadeSlideInOut]="isFiltersVisible ? 'in' : 'out'">
                            <!-- Checkbox Tous -->
                            <moffi-ui-checkbox [checked]="allComplete" [indeterminate]="someComplete()" 
                                (onValueChangeEvent)="setAll($event.checked)"
                                label="{{'marketplace.category.all' | translate}}">
                            </moffi-ui-checkbox>
                            <!-- Liste des catégories -->
                            <div *ngFor="let tag of getEnumKeys()">
                                <moffi-ui-checkbox [checked]="selectedTags[tag]" 
                                    (onValueChangeEvent)="onCheckboxChange(tag, $event.checked)"
                                    label="{{ tag | translate}}">
                                </moffi-ui-checkbox>
                            </div>
                        </div>
                    </div>  
                </div>
                <!-- Résultats -->
                <div *ngIf="filteredPartners.length != 0" class="partners-results-mobile">
                    <div *ngFor="let partner of filteredPartners">
                        <app-marketplace-item 
                            (onClickEvent)="openPartnerDetail($event)" 
                            [modelPartner]="partner" 
                            width="100%" 
                            height="12rem">
                        </app-marketplace-item>
                    </div>
                </div>
                <!-- Aucun résultat -->
                <div *ngIf="filteredPartners.length == 0" class="partners-no-result-mobile">
                    <!-- Image loupe-->
                    <img src="assets/images/icon/icon_search.webp" alt="icon_search">
                    <!-- Text -->
                    <h3 class="fw-700">{{'marketplace.discover.empty.title' | translate}}</h3>
                    <h4 class="fs-16">{{'marketplace.discover.empty.text' | translate}}</h4>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Template de la page Partenaire - Voir component PartnerComponent -->
<router-outlet></router-outlet>
