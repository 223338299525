import { Component, Input } from '@angular/core';
import { PanelCardSmallComponent } from '../panel-card-small/panel-card-small.component';

@Component({
  selector: 'app-panel-card-large',
  templateUrl: './panel-card-large.component.html',
  styleUrls: ['./panel-card-large.component.scss']
})
export class PanelCardLargeComponent extends PanelCardSmallComponent {

  // Icone Image
  @Input() iconSrc!: string;
  @Input() iconAlt!: string;

  // Link
  @Input() link!: string;

  // Link color
  @Input() linkColor: string = "#45C6AA";

  // Paragraphe
  @Input() pTitle!: string;
  @Input() pText!: string;





}
