import { Component } from '@angular/core';
import { AbstractPageComponent } from '../abstract/page/abstract.page.component';
import { ScrollAnimation } from 'src/app/utils/scroll-animations';

@Component({
  selector: 'app-waldo',
  templateUrl: './waldo.component.html',
  styleUrl: './waldo.component.scss'
})
export class WaldoComponent extends AbstractPageComponent {

  // Liste des clients Moffi
  clientsWaldo: string[] = [
    'degussa_bank',
    'rolls_royce',
    'bureau_veritas',
    'pernod_ricard',
    'nexity',
    'somfy',
    'world_vision',
    'systra',
  ];

  override setHeaderMetadatas(): void {
    this.title.setTitle(this.getTranslation('waldo.metadata.title'));
    this.meta.updateTag({
      name: 'description',
      content: this.getTranslation('waldo.metadata.description'),
    });
  }
  override launchAnimations(): void {
    // Animations du header
    let fadePanelHeader: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelHeader.handleScroll('.fadePanelHeader','fadeIn 0.8s ease-in-out forwards',0.3,0);

    // Animation du panel Client
    let fadePanelClient: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelClient.handleScroll('.fadePanelClient', 'fadeIn 0.5s ease-in-out forwards',0.15,0);

    // Animation du panel Meeting
    this.createAnimationHorizontalPanel('.fadePanelMeeting');
    // Animation du panel Calendar
    this.createAnimationHorizontalPanel('.fadePanelCalendar');
    // Animation du panel Booking
    this.createAnimationHorizontalPanel('.fadePanelBooking');

    // Animation du panel Accompagnement
    let fadePanelSupport: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelSupport.handleScroll('.fadePanelSupport','fadeIn 0.8s ease-in-out forwards',0.2,0);

    // Animation du panel Amabassadeur
    let fadePanelTestimony: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelTestimony.handleScroll('.fadePanelTestimony','fadeIn 0.8s ease-in-out forwards',0.2,0);
    
  }

}
